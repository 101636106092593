import type { SystemPageData, SystemPage } from '../system';
import type { Handler } from '../types';
import type { Product } from './types';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { initPageContent } from '../system';
import { pageQuery, previewPageQuery } from './queries';
import { RouteName } from 'routes';

const handler: Handler<ReturnOrderRouteData, CreateReturnOrderPage> = (routeData, _state$, { api }) => 
  api.graphApi<PageQueryResponse>(
    routeData.params.previewToken ? previewPageQuery : pageQuery,
  ).pipe(
    map(({ pages, settings: { documents: { returnOrder } } }) => {
      const page = pages.createDocFreeReturnOrder;

      if (!page)
        return null;

      const result = { page: initPageContent(page) as CreateReturnOrderPage };

      result.page.products = {};
      result.page.returnReasons = returnOrder.reasons ?? { forOrder: [], forOrderLines: [] };
      result.page.fileUploadSettings = returnOrder.attachments;

      result.page.component = PageComponentNames.CreateDocFreeReturnOrder;

      return result;
    }),
  );

export default handler;

type ReturnOrderRouteData = {
  routeName: RouteName.CreateDocFreeReturnOrder;
  params: {
    previewToken?: string;
  };
};

type CreateReturnOrderPage = SystemPage & {
  component: PageComponentNames.CreateDocFreeReturnOrder;
  returnReasons: Reasons;
  fileUploadSettings: FileUploadSettings;
  products: Record<string, Product>;
};

type PageQueryResponse = {
  pages: {
    createDocFreeReturnOrder: SystemPageData;
  };
  settings: {
    documents: {
      returnOrder: ReturnOrderSettings;
    };
  };
};

type ReturnOrderSettings = {
  attachments: FileUploadSettings;
  reasons?: Reasons;
};

type Reasons = {
  forOrder: {
    id: string;
    name: string;
  }[];
  forOrderLines: {
    id: string;
    name: string;
  }[];
};

type FileUploadSettings = {
  acceptedTypes: string[];
  allowMultiple: boolean;
  maxNameLength: number;
  maxSize: number;
};

import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import type { Api } from 'utils/api';
import type { StateObservable } from 'redux-observable';
import type { AppState } from 'behavior';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { getBackTo, initComponent } from '../helpers';
import { of } from 'rxjs';
import { map, switchMap, pluck, first } from 'rxjs/operators';
import { StoreType, areSettingsLoaded } from 'behavior/settings';
import { LoginPage } from './types';
import { ContentSystemPageData, initContentSystemPage, loadContentSystemPageQuery } from '../system';

const handler: Handler<LoginRouteData, LoginPage> = (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(areSettingsLoaded),
    switchMap(
      settings => settings.storeType === StoreType.Closed
        ? loadClosedStoreLoginPage(routeData, state$)
        : loadSystemLoginPage(routeData, state$, api),
    ),
  );
};

export default handler;

function loadClosedStoreLoginPage(routeData: LoginRouteData, state$: StateObservable<AppState>) {
  return of({
    emptyLayout: true,
    backTo: _getBackTo(routeData, state$),
  }).pipe(
    initComponent(PageComponentNames.ClosedStoreLogin),
  );
}

function loadSystemLoginPage(routeData: LoginRouteData, state$: StateObservable<AppState>, api: Api) {
  return api.graphApi<LoginPageResponse>(loadContentSystemPageQuery('login')).pipe(
    map(({ pages: { login } }) => ({
      page: {
        ...initContentSystemPage(login),
        component: PageComponentNames.Login as const,
        backTo: routeData.options && routeData.options.backTo || _getBackTo(routeData, state$),
      },
    })),
  );
}

function _getBackTo(routeData: LoginRouteData, state$: StateObservable<AppState>) {
  return getBackTo(state$, [
    RouteName.Login,
    RouteName.Registration,
    RouteName.ResetPassword,
    RouteName.ForgotPassword,
  ], routeData.params && routeData.params.language);
}

type LoginRouteData = {
  routeName: RouteName.Login;
  params?: {
    language: number;
  };
  options?: {
    backTo?: BackTo;
  };
};

type LoginPageResponse = {
  pages: {
    login: ContentSystemPageData;
  };
};

import styles from './ProductGroup.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'components/primitives/grid';
import ProductGroupTabs from './ProductGroupTabs';
import ProductGroupProvider from './ProductGroupProvider';
import BackLink from '../backToOverviewBlock/BackLink';
import { trackProductListView, EventSource } from 'behavior/analytics';
import { useTracking } from '../productList/hooks';
import { DocumentTitle, useDocumentTitle } from '../documentTitle';
import { MediaGallery } from 'components/objects/mediaGallery';
import { generateKey } from 'utils/helpers';
import SizeChartDownloadLink from './SizeChartDownloadLink';
import { renderHTML } from 'utils/render';

const Page = ({ title, description, media, products, backTo, id, customLengths, isOrderTypeAdded }) => {
  const galleryKey = useMemo(() => generateKey(), [media]);
  const dispatch = useDispatch();
  useTracking(products,
    ({ products }) => dispatch(trackProductListView({ products, source: EventSource.ProductGroup })),
    [products],
  );
  const isDress = products && products.length > 0 ? products[0].isDress : false;
  const season = products && products.length > 0 ? products[0].season : '';

  const noImageLarge = useSelector(state => state.settings.product.noImage.large);
  const pageTitle = title || id;
  const documentTitle = useDocumentTitle(pageTitle);

  return (
    <ProductGroupProvider key={pageTitle}>
      {documentTitle && <DocumentTitle title={documentTitle} />}
      <Container>
        {backTo && <div className={styles.backToContainer}>
          <BackLink node={{ link: { to: backTo.routeData, url: backTo.url } }} />
        </div>}
        <Row className={`${styles.displayMdBlock} clearfix`}>
          <Col xs={{ size: 12, order: 1 }} md={6}
            className={`${styles.displayMdBlock} ${styles.floatLeft}`}
          >
            <MediaGallery index={0} items={media} noImageLarge={noImageLarge} key={galleryKey} horizontal={false} />
          </Col>
          <Col xs={{ size: 12, order: 2 }} md={6}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <div className={styles.overview}>
              <h1 className={styles.productTitle}>{pageTitle}</h1>
            </div>
            <div>
              {season && <span>{season}</span>}
            </div>
            <div>
              {isDress && <SizeChartDownloadLink />}
            </div>
            {!!description &&
              <div>
                <div className="fr-view">{renderHTML(description)}</div>
              </div>
            }
          </Col>
        </Row>
      </Container>
      <Container>
        <ProductGroupTabs products={products} customLengths={customLengths} isOrderTypeAdded={isOrderTypeAdded} />
      </Container>
    </ProductGroupProvider>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  media: PropTypes.array,
  products: PropTypes.array,
  backTo: PropTypes.shape({
    url: PropTypes.string,
    routeData: PropTypes.object.isRequired,
  }),
  id: PropTypes.string.isRequired,
  customLengths: PropTypes.array,
  isOrderTypeAdded: PropTypes.bool,
};

export default Page;

import type { Handler } from '../types';
import type { AgreementLine, SalesAgreement, SalesAgreementsState } from './types';
import type { SystemPage, SystemPageData } from '../system';
import type { RouteData } from 'routes';
import type { AppState } from 'behavior';
import type { RowContentElementData } from 'behavior/content';
import { map, filter, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initComponent } from '../helpers';
import { agreementDetailPageQuery } from './queries';
import { requestAgreementLinesAvailability } from './actions';
import { initSystemPageContent, loadSystemPageQuery } from '../system';
import { SalesAgreementStatus } from 'behavior/salesAgreements';
import { RouteName } from 'routes';
import { StateObservable } from 'redux-observable';
import { areAnalyticsSettingsLoaded } from 'behavior/analytics';

const salesAgreementHandler: Handler<SalesAgreementPageRouteData, SalesAgreementPage | NotFoundPage> = ({
  params: {
    id: agreementId,
    agreementLine,
    previewToken,
  },
}, state$, { api }) => {
  if (previewToken) {
    return api.graphApi<SystemPageResponse>(loadSystemPageQuery('salesAgreement')).pipe(
      map(({ pages: { salesAgreement: page } }) => !page ? null : ({
        page: {
          ...page,
          component: PageComponentNames.SalesAgreement,
          agreement: {
            id: '',
            title: '',
            status: SalesAgreementStatus.Active,
            currency: { id: '' },
            effectiveDate: '-',
            expirationDate: '-',
            address: '-',
            lines: Array.from(Array(3)).map((_, index) => ({
              id: index.toString(),
              quantities: {},
              amounts: {},
              uom: { id: '' },
            } as AgreementLine)),
          },
        },
      })),
      initSystemPageContent(),
    );
  }

  return state$.pipe(
    first(areAnalyticsSettingsLoaded),
    switchMap(({ analytics }) =>
      api.graphApi<AgreementDetailPageResponse>(agreementDetailPageQuery, { agreementId, loadCategories: analytics.isTrackingEnabled }).pipe(
        map(data => mapData(data, state$, agreementLine)),
        initComponent(PageComponentNames.SalesAgreement),
        filter(d => d !== null),
        initSystemPageContent(),
        map(data => ({
          ...data,
          action$: of(requestAgreementLinesAvailability(data.page.agreement)),
        })),
      ),
    ),
  );
};

export default salesAgreementHandler;

function mapData(
  {
    pages: {
      salesAgreement: page,
    },
  }: AgreementDetailPageResponse,
  state$: StateObservable<AppState>,
  agreementLineId?: string,
) {
  if (!page || !page.agreement)
    return null;

  for (const line of page.agreement.lines) {
    if (line.category) {
      if (line.category.type === 'NotExistingProductCategory') {
        delete line.category.type;
        line.category.exists = false;
      } else {
        line.category.exists = true;
      }
    }
  }

  page.agreement.expandedAgreementLineId = agreementLineId;

  const currentPageState = state$.value.page as SalesAgreementsState;
  if (currentPageState && currentPageState.agreement && currentPageState.agreement.id === page.agreement.id)
    page.search = currentPageState.search;

  return page as SystemPageData & { agreement: AgreementData };
}

type SalesAgreementPage = SystemPage & {
  component: PageComponentNames;
  agreement: SalesAgreement | null;
};

type NotFoundPage = {
  component: PageComponentNames.NotFound;
};

type SalesAgreementPageRouteData = RouteData & {
  routeName: RouteName;
  params: {
    id: string;
    agreementLine?: string;
    previewToken?: string;
  };
};

type SystemPageResponse = {
  pages: {
    salesAgreement: {
      metaTitle: string | null;
      content: {
        header: RowContentElementData[] | null;
        footer: RowContentElementData[] | null;
      } | null;
    } | null;
  };
};

type AgreementData = {
  id: string;
  effectiveDate: string | null;
  expirationDate: string | null;
  currency: {
    id: string;
    cultureName: string;
    symbol: string;
    decimalDigits: number;
  };
  address: string | null;
  lines: AgreementLine[];
  status: SalesAgreementStatus;
  title: string | null;
  url: string;
  expandedAgreementLineId?: string;
};

type AgreementDetailPageResponse = SystemPageResponse & {
  pages: {
    salesAgreement: {
      agreement: {
        id: string;
        effectiveDate: string | null;
        expirationDate: string | null;
        currency: {
          id: string;
          cultureName: string;
          symbol: string;
          decimalDigits: number;
        };
        address: string | null;
        lines: {
          id: string;
          category: {
            id: string;
            name: string;
            type?: string;
            exists?: boolean;
          } | null;
          discountPercent: number | null;
          price: number | null;
          effectiveDate: string | null;
          expirationDate: string | null;
          isMaxEnforced: boolean;
          product: {
            id: string;
            title: string | null;
            url: string | null;
            exists: boolean;
            isOrderable: boolean | null;
            inventory: number | null;
            stockLevels: {
              outOfStock: number;
              lowStock: number;
              maxStockNumber: number | null;
            } | null;
            uom: {
              id: string;
              description: string | null;
            } | null;
            uoms: {
              id: string;
              description: string | null;
              quantityStep: number;
              maximumQuantity: number | null;
              minimumQuantity: number | null;
            }[] | null;
            categoriesPaths: {
              categories: {
                id: string;
                name: string;
              }[];
            };
            productConfiguratorInfo: {
              isProductConfigurable: boolean | null;
              configuratorId: string | null;
              modelId: string | null;
            } | null;
            variantComponentGroups: {
              id: string;
              name: string | null;
              components: {
                id: string | null;
                name: string | null;
                variants: string[];
              }[];
            }[];
            variants: {
              id: string;
              isOrderable: boolean | null;
            }[] | null;
          } | null;
          uom: {
            id: string;
            description: string | null;
          } | null;
          quantities: {
            value: number | null;
            invoiced: number | null;
            released: number | null;
            remaining: number | null;
            shipped: number | null;
          };
          amounts: {
            value: number | null;
            invoiced: number | null;
            max: number | null;
            min: number | null;
            released: number | null;
            remaining: number | null;
            shipped: number | null;
          };
          location: {
            code: string | null;
            title: string | null;
          } | null;
        }[];
        status: SalesAgreementStatus;
        title: string | null;
        url: string;
        expandedAgreementLineId?: string;
      } | null;
      search?: {
        keywords: string;
        ids: string[] | null;
      };
    } | null;
  };
};
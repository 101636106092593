import type { Handler } from 'behavior/pages/types';
import type { RouteName } from 'routes';
import type {
  Product,
  CalculatedProduct,
  ProductImage,
  ProductVideo,
  RelatedProduct,
  RelatedProductGroup,
} from './types';
import type { AppState } from 'behavior';
import type { LoadedSettings } from 'behavior/settings';
import type { ProductPageTemplate } from 'behavior/pageTemplates/types';
import { createProductPageQuery } from './queries';
import { map, first, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { RowContentElement, RowContentElementData, parseContent } from 'behavior/content';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { routesBuilder } from 'routes';
import { ProductMediaType, Preset } from './constants';
import { PageComponentNames } from '../componentNames';
import { printModeEnabled } from 'behavior/printMode';
import { productPageTemplateLoaded } from 'behavior/pageTemplates';
import { parseVideoData } from 'utils/video';
import { areAnalyticsSettingsLoaded } from 'behavior/analytics';

const handler: Handler<ProductRouteData, ProductPage> = ({ params: { id, agreementLine: salesAgreementLineId, language } }, state$, { api, scope }) => id
  ? state$.pipe(
    first(areAnalyticsSettingsLoaded),
    switchMap(state => {
      const productPageTemplate = state.pageTemplates.product;
      const loadPageTemplate = !productPageTemplate
        || language !== productPageTemplate.languageId
        || !!productPageTemplate.expired;
      const options = {
        scope,
        isInsiteEditor: state.insiteEditor.initialized,
        isProductGroupingEnabled: (state.settings as LoadedSettings).product.productGrouping.isEnabled,
        loadPageTemplate,
      };

      const query = createProductPageQuery(options);
      const isTrackingEnabled = state.analytics && state.analytics.isTrackingEnabled;
      const variables = {
        productId: id,
        specificationFilter: ProductSpecificationFilter.ForDetails,
        loadRelatedProductsCategories: isTrackingEnabled,
        loadUom: isTrackingEnabled,
      };

      const isPrintMode = printModeEnabled(state.routing);

      return api.graphApi<ProductPageResponse>(query, variables).pipe(
        map(({ pages: { product: productPageData }, pageTemplates }) => {
          if (!productPageData)
            return null;

          const { header, ...restProductPageData } = productPageData;
          const productData = productPageData.product;
          const product = {
            ...mergeWithProductFromState(productData, state),
            relatedProductGroups: parseRelatedProducts(productData.relatedProductGroups),
            media: processMedia(productData.media),
            defaultUom: productData.uom,
          };

          const templatePreset = loadPageTemplate ? pageTemplates.product.preset : state.pageTemplates.product!.preset;
          const preset = productPageData.preset || templatePreset;

          const page: ProductPage = {
            ...restProductPageData,
            component: PageComponentNames.Product,
            desktop: productPageData.desktop && parseContent(productPageData.desktop),
            mobile: productPageData.mobile && parseContent(productPageData.mobile),
            index: !isPrintMode,
            isPrintMode,
            product,
            preset,
            title: header ?? product.title,
          };

          if (!!salesAgreementLineId)
            page.salesAgreement = { preSelectedLine: { id: salesAgreementLineId } };

          return {
            page,
            action$: loadPageTemplate
              ? of(productPageTemplateLoaded(pageTemplates.product, language || null))
              : undefined,
          };
        }));
    }),
  )
  : of(null);

export default handler;

function mergeWithProductFromState(product: ProductData, state: AppState) {
  if (state.routing.navigatingTo?.location === state.routing.location)
    return product;

  const page = state.page as { product: Product & CalculatedProduct };
  if (page.product == null || page.product.id !== product.id)
    return product;

  return {
    ...page.product,
    ...product,
    loaded: false,
  };
}

export function parseRelatedProducts(relatedProductGroups: RelatedProductGroupData[] | null): RelatedProductGroup[] | null {
  if (!relatedProductGroups)
    return null;

  for (const group of relatedProductGroups)
    for (const product of group.products)
      (product as RelatedProduct).routeData = routesBuilder.forProduct(product.id);

  return relatedProductGroups as RelatedProductGroup[];
}

export function processMedia(media: ProductMediaData[] | null) {
  if (!media)
    return null;

  return media.map(mediaItem => {
    if (mediaItem.type === ProductMediaType.Video) {
      const videoData = parseVideoData(mediaItem.url);

      return {
        type: mediaItem.type,
        videoData,
      };
    }

    return mediaItem;
  }).filter((mediaItem): mediaItem is ProductMedia => {
    if (mediaItem.type === ProductMediaType.Video)
      return !!mediaItem.videoData;

    return !!mediaItem.small || !!mediaItem.medium || !!mediaItem.large;
  });
}

type ProductMediaData = ProductImage | Omit<ProductVideo, 'videoData'> & { url: string };
type ProductMedia = ProductImage | ProductVideo;

type RelatedProductGroupData = Omit<RelatedProductGroup, 'products'> & {
  products: Omit<RelatedProduct, 'routeData'>[];
};

type ProductData = Omit<Product, 'media' | 'relatedProductGroups'> & {
  media: ProductMediaData[] | null;
  relatedProductGroups: RelatedProductGroupData[] | null;
};

type ProductPageData = {
  metaTitle: string | null;
  metaDescription: string | null;
  header: string | null;
  preset: Preset;
  desktop: RowContentElementData[] | null;
  mobile: RowContentElementData[] | null;
  product: ProductData;
};

type ProductPageResponse = {
  pages: {
    product: ProductPageData | null;
  };
  pageTemplates: {
    product: ProductPageTemplate;
  };
};

type ProductRouteData = {
  routeName: RouteName.ProductDetails;
  params: {
    id: string;
    agreementLine?: string;
    language?: number;
  };
};

type ProductPage = {
  component: PageComponentNames.Product;
  metaTitle: string | null;
  metaDescription: string | null;
  preset: Preset;
  desktop: RowContentElement[] | null;
  mobile: RowContentElement[] | null;
  product: Product | Product & CalculatedProduct & { loaded: false };
  salesAgreement?: {
    preSelectedLine?: {
      id: string;
    };
  };
  index: boolean;
  isPrintMode: boolean;
  title: string | null;
};

import { Children } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { useIsVisualDesigner } from 'utils/hooks';

let ContentBlocksSortableList;
let ColumnToolbar;

const VdColumnContentWrapper = ({ children, columnId, contentOrientation }) => {
  if (!useIsVisualDesigner())
    return children;

  if (!ContentBlocksSortableList)
    ContentBlocksSortableList = loadable(() => import(/*webpackChunkName:"vd"*/'../sortableLists/ContentBlocksSortableList'));

  if (!ColumnToolbar)
    ColumnToolbar = loadable(() => import(/*webpackChunkName:"vd"*/'./columnToolbar/ColumnToolbar'));

  const childrenLength = Children.count(children);

  return (
    <>
      <ColumnToolbar isEmptyColumn={childrenLength === 0} columnId={columnId} />
      <ContentBlocksSortableList columnId={columnId} contentOrientation={contentOrientation}>
        {children}
      </ContentBlocksSortableList>
    </>
  );
};

VdColumnContentWrapper.propTypes = {
  children: PropTypes.node,
  columnId: PropTypes.string.isRequired,
  contentOrientation: PropTypes.string,
};

export default VdColumnContentWrapper;
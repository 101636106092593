import type { Epic } from 'behavior/types';
import type { Product } from './types';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, pluck, map, mergeMap, startWith } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormName } from 'behavior/pages';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { toasts } from 'behavior/toasts';
import { requestAbility } from 'behavior/user/epic';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import {
  CreateDocFreeReturnOrderAction,
  DOC_FREE_RETURN_ORDER_SUBMITTED,
  DOC_FREE_RETURN_ORDER_PRODUCTS_REQUESTED,
  setReturnOrderFailed,
  receiveProducts,
} from './actions';
import {
  returnOrderMutation, 
  productsQuery,
} from './queries';

const epic: Epic<CreateDocFreeReturnOrderAction> = (action$, state$, dependencies) => {
  const createReturnOrder$ = action$.pipe(
    ofType(DOC_FREE_RETURN_ORDER_SUBMITTED),
    pluck('payload'),
    switchMap(
      ({ input, files }) => dependencies.api.graphApi<CreateReturnOrderResponse>(
        returnOrderMutation, { input }, { files, retries: 0 },
      ).pipe(
        pluck('documents', 'returnOrders', 'create', 'orderId'),
        mergeMap(orderId => {
          if (!orderId)
            return of(setReturnOrderFailed(), unsetLoadingIndicator());

          toasts.success('', { textKey: 'ReturnRequest_OrderCreated' });

          return requestAbility(AbilityTo.ViewReturnOrders, state$, dependencies).pipe(
            map(abilityState => abilityState === AbilityState.Available),
            map(canViewReturnOrders => navigateTo(canViewReturnOrders
              ? routesBuilder.forDocument(orderId, DocumentType.ReturnOrder)
              : routesBuilder.forMyAccount())),
          );
        }),
        catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormName.CreateDocFreeReturnOrder), unsetLoadingIndicator())),
        retryWithToast(action$, dependencies.logger, () => of(unlockForm(FormName.CreateDocFreeReturnOrder), unsetLoadingIndicator())),
        startWith(setLoadingIndicator()),
      ),
    ),
  );

  const requestProducts$ = action$.pipe(
    ofType(DOC_FREE_RETURN_ORDER_PRODUCTS_REQUESTED),
    pluck('payload', 'ids'),
    switchMap(
      ids => dependencies.api.graphApi<ProductsQueryResponse>(
        productsQuery, { options: { ids } },
      ).pipe(
        pluck('catalog', 'products', 'products'),
        map(receiveProducts),
        retryWithToast(action$, dependencies.logger),
      ),
    ),
  );

  return merge(createReturnOrder$, requestProducts$);
};

export default epic;

type CreateReturnOrderResponse = {
  documents: {
    returnOrders: {
      create: {
        orderId: string | null;
      };
    };
  };
};

type ProductsQueryResponse = {
  catalog: {
    products: {
      products: Product[];
    };
  };
};

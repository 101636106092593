import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { useIsVisualDesigner } from 'utils/hooks';

let VdContentBlockWrapper;

const VdContentBlockContainer = ({ children, id, index }) => {
  if (!useIsVisualDesigner())
    return children;

  if (!VdContentBlockWrapper)
    VdContentBlockWrapper = loadable(() => import(/*webpackChunkName:"vd"*/'./contentBlock/VdContentBlockWrapper'));

  return (
    <VdContentBlockWrapper index={index} id={id}>
      {children}
    </VdContentBlockWrapper>
  );
};

VdContentBlockContainer.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  id: PropTypes.string.isRequired,
};

export default VdContentBlockContainer;
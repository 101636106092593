import type { ProductGroupPageResponse } from './types';
import type { Epic } from 'behavior/types';
import type { ProductGroupAction } from './actions';
import type { LoadedSettings, Settings } from 'behavior/settings';
import { ofType } from 'redux-observable';
import { mergeMap, map, pluck, concatMap, catchError, startWith, filter, withLatestFrom } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import { PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED, productsUpdated } from './actions';
import { createLoadCalculatedFieldsQuery, loadSpecificationsSettings } from './queries';
import { merge, of, throwError } from 'rxjs';
import { settingsLoaded, setUpdating } from 'behavior/settings';

type SettingsResponse = {
  settings: Partial<Settings>;
};

const productGroupEpic: Epic<ProductGroupAction> = (action$, state$, { api, logger }) => {
  const specificationsSettings$ = action$.pipe(
    ofType(PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED),
    filter(_ => {
      const settings = state$.value.settings as LoadedSettings;
      return settings && settings.product.productGrouping.isEnabled && !settings.product.productGrouping.specifications;
    }),
    mergeMap(_ => api.graphApi<SettingsResponse>(loadSpecificationsSettings).pipe(
      pluck('settings'),
      map(settingsLoaded),
      catchError(e => merge(of(settingsLoaded()), throwError(e))),
      startWith(setUpdating())),
    ),
  );

  const calculatedFields$ = action$.pipe(
    ofType(PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED),
    mergeMap(({ payload: { groupId, options } }) => {
      const ids = options?.ids;
      const productsCountPerBatch = options?.productsCountPerBatch;

      if (ids === undefined || productsCountPerBatch === undefined)
        return [{ groupId, options }];

      const payloads: {
        groupId: string;
        options: { ids: string[] };
      }[] = [];

      const idsCopy = [...ids];

      for (let index = 0; index < idsCopy.length; index + productsCountPerBatch) {
        const batchIds = idsCopy.splice(index, productsCountPerBatch);
        payloads.push({ groupId, options: { ids: batchIds } });
      }

      return payloads;
    }),
    withLatestFrom(state$.pipe(
      map(s => createLoadCalculatedFieldsQuery(s.insiteEditor.initialized)),
    )),
    concatMap(([payload, query]) => api.graphApi<ProductGroupPageResponse>(query, payload).pipe(
      pluck('pages', 'productGroupDetails', 'productGroup'),
      map(productGroup => productsUpdated(productGroup ? productGroup.products : null)),
      retryWithToast(action$, logger),
    )),
  );

  return merge(specificationsSettings$, calculatedFields$);
};

export default productGroupEpic;

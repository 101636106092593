import styles from './NotFound.module.scss';
import { Link, BackLink } from 'components/primitives/links';
import { useSanaTexts } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { InfoAlert } from 'components/primitives/alerts';
import { Placeholder } from 'components/primitives/placeholders';
import { makeSimpleText, makeRichText } from 'utils/render';

const NotFound = () => {
  const {
    loaded,
    texts: [backLinkText, homeLinkText, msg, checkAddress, backTemplate, homeTemplate],
  } = useSanaTexts([
    'PageNotFound_BackLink',
    'ErrorPages_HomepageLink',
    'PageNotFound_IntroText',
    'PageNotFound_CheckAddress',
    'PageNotFound_BackLinkTemplate',
    'ErrorPages_HomepageLinkTemplate',
  ]);

  const placeholder = loaded ? '' : <Placeholder className={styles.loading} />;

  const backLink = loaded && <BackLink>{backLinkText}</BackLink>;
  const homePageLink = loaded && <Link to={routesBuilder.forHome}>{homeLinkText}</Link>;

  return (
    <InfoAlert className={styles.block}>
      <div>
        {placeholder || makeRichText(msg)}
      </div>
      <ul className={styles.actionList}>
        <li>
          {placeholder || makeSimpleText(checkAddress)}
        </li>
        <li>
          {placeholder || makeSimpleText(backTemplate, [backLink], true)}
        </li>
        <li>
          {placeholder || makeSimpleText(homeTemplate, [homePageLink], true)}
        </li>
      </ul>
    </InfoAlert>
  );
};

export default NotFound;

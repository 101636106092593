import { memo, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useIsVisualDesigner, useIsInitialNavigation } from 'utils/hooks';
import { setStatusText } from 'behavior/ariaStatus';
import { pageTitleIsSet } from 'behavior/analytics';
import { Helmet } from 'react-helmet';

const DocumentTitle = ({ title, announceOnAppInit, skipAnnounce }) => {
  const pendingToApplyTitleRef = useRef();
  const dispatch = useDispatch();
  const omitScroll = useSelector(s => s.routing.location.state?.omitScroll || s.page.omitScroll);
  const isTrackingEnabled = useSelector(s => s.analytics?.isTrackingEnabled);
  const [ariaPageChanged] = useSanaTexts(['Aria_PageChanged']).texts;
  const isVisualDesigner = useIsVisualDesigner();
  const initialNavigation = useIsInitialNavigation();

  const shouldSkipAnnounce = isVisualDesigner
    || (initialNavigation && !announceOnAppInit)
    || skipAnnounce
    || omitScroll;

  useEffect(() => {
    if (shouldSkipAnnounce || !title.value)
      return;

    dispatch(setStatusText(makeSimpleText(ariaPageChanged, [title.value]), true));
  }, [title]);

  const handleClientStateChange = useMemo(() => {
    if (!isTrackingEnabled)
      return null;

    return ({ title }) => {
      if (pendingToApplyTitleRef.current && pendingToApplyTitleRef.current === title) {
        dispatch(pageTitleIsSet());
        pendingToApplyTitleRef.current = undefined;
      }
    };
  }, [isTrackingEnabled]);

  useEffect(() => {
    if (!isTrackingEnabled || !title.value)
      return;

    if (document.title === title.value)
      dispatch(pageTitleIsSet());
    else
      pendingToApplyTitleRef.current = title.value;
  }, [title]);

  return (
    <Helmet onChangeClientState={handleClientStateChange}>
      <title>{title.value}</title>
    </Helmet>
  );
};

DocumentTitle.propTypes = {
  title: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  announceOnAppInit: PropTypes.bool,
  skipAnnounce: PropTypes.bool,
};

export default memo(DocumentTitle);

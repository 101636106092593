import type { Action } from 'redux';
import type { Epic, StoreDependencies } from 'behavior/types';
import type { Api } from 'utils/api';
import type { Mandatory } from 'utils/types';
import type { AppState } from 'behavior';
import type { LoadedSettings } from 'behavior/settings';
import type {
  ReceivedBasket,
  ReceivedSummary,
  EmptyModel,
  ModifiedLine,
  ModifiedLines,
  SalesAgreementInfoData,
  SalesAgreementInfo,
} from '../types';
import { merge, of, concat, throwError, EMPTY, timer } from 'rxjs';
import {
  delay,
  first,
  concatMap,
  mergeMap,
  catchError,
  takeUntil,
  pluck,
  filter,
  switchMapTo,
  switchMap,
  map,
  ignoreElements,
  mergeMapTo,
  startWith,
} from 'rxjs/operators';
import { concatToIfEmpty } from 'utils/rxjs';
import {
  BASKET_ADD_PRODUCTS,
  BASKET_RECEIVED,
  BASKET_PAGE_REQUESTED,
  BASKET_SUMMARY_REQUESTED,
  BASKET_UPDATED,
  BASKET_UPDATE,
  BASKET_CLEAR,
  SPECIAL_ORDER_TYPE_UPDATE,
  SPECIAL_ORDER_POPUP,
  basketUpdated,
  basketReceived,
  basketSummaryReceived,
  updatedSpecialOrderType,
  BASKET_AGREEMENT_LINES_REQUESTED,
  receiveAgreementLines,
  modifyBasket,
  specialOrderPopupShow,
  BasketAction,
} from '../actions';
import {
  addProductsMutation, clearNonOrderablesMutation, basketDetailsQuery, basketSummaryQuery,
  getUpdateQuery, clearBasketMutation, deleteBasketMutation, pageSize, saveLinesOnlyMutation,
  applySalesAgreementAndAddProductsMutation,
  salesAgreementQuery, updateOrderType,
} from '../queries';
import { RouteName } from 'routes';
import { retryWithToast, catchBasketCalculationError } from 'behavior/errorHandling';
import { NAVIGATED, RoutingAction } from 'behavior/routing';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { Updaters } from '../constants';
import { routesBuilder } from 'routes';
import { getCorrectPageIndex, redirectToPage, isBasketModel, isQuickOrderModel } from '../helpers';
import { ofType, StateObservable } from 'redux-observable';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import {
  trackAddToBasket,
  trackRemoveFromBasket,
  getModifiedProductsTrackingData,
  getProductsTrackingDataFromLines,
} from 'behavior/analytics';
import { skipIfPreviewWithToast } from 'behavior/preview';
import { setErrorMode } from 'behavior/basket/actions.errorMode';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { requestAbility } from 'behavior/user/epic';

const epic: Epic<BasketAction | RoutingAction> = (action$, state$, dependencies) => {
    const { api, logger } = dependencies;

    const logBasketRetrievalError = (e: unknown) => logger.error('The following error occurred during basket retrieval:', e);
    const setLoading = setLoadingIndicator();
    const unsetLoading = unsetLoadingIndicator();

    //[169489] [MadiLane] 3.5. Separate Special order for Bride or Stock.
    const orderTypePopup = action$.pipe(
        ofType(SPECIAL_ORDER_POPUP),
        map(() => specialOrderPopupShow()),
    );

    //[169489] [MadiLane] 3.5. Separate Special order for Bride or Stock.
    const orderTypeUpdate$ = action$.pipe(
        ofType(SPECIAL_ORDER_TYPE_UPDATE),
        skipIfPreviewWithToast(state$, dependencies),
        concatMap(({ payload: { stockReference, brideName, weddingDate, brideNameRequired, refNumberRequired } }) => {
            const addProductsQuery = updateOrderType;
            const addProductsVariables = {
                ...({ stockReference }),
                ...({ brideName }),
                ...({ weddingDate }),
                ...({ brideNameRequired }),
                ...({ refNumberRequired }),
            };
            const basket = { stockReference, brideName, weddingDate, brideNameRequired, refNumberRequired };
            const mapToBasketSpecialOrderType = map(() => {
                return updatedSpecialOrderType(basket);

            });

            return api.graphApi(addProductsQuery, addProductsVariables).pipe(
                mergeMap(() => [unsetLoading, modifyBasket([])]),
                retryWithToast(action$, logger, _ => of(unsetLoading)),
                //startWith(setLoading),
                mapToBasketSpecialOrderType,
             /*   modifyBasket([]),*/
                catchError(e => {
                    logger.error(e);
                    return EMPTY;
                }),
                retryWithToast(action$, logger),
            );
        }),
    );

    const basketAdd$ = action$.pipe(
        ofType(BASKET_ADD_PRODUCTS),
        skipIfPreviewWithToast(state$, dependencies), 
        concatMap(({ payload: { lines, updatedById, agreementId }}) => {
            const date = Date.now();
            const addProductsQuery = agreementId ? applySalesAgreementAndAddProductsMutation : addProductsMutation;
            const addProductsVariables = {
                lines,
                addedLinesCount: lines.length,
                requestModifiedLines: isTrackingEnabled(state$.value),
                ...(agreementId ? { agreementId } : undefined),
            };

            return api.graphApi<AddProductsResponse | ApplySalesAgreementAndAddProductsResponse>(addProductsQuery, addProductsVariables).pipe(
                mergeMap(({ basket }) => {
                    const settings = state$.value.settings;
                    const routeName = state$.value.routing.routeData?.routeName;
                    const actions: Array<Action> = [
                        basketUpdated(updatedById, date),
                        basketChangeCompleted(lines.length),
                    ];

                    const addedLines = basket?.addProducts.modifiedLines?.list;
                    if (addedLines?.length) {
                        const addedProducts = getProductsTrackingDataFromLines(addedLines);
                        if (addedProducts.length)
                            actions.push(trackAddToBasket({ products: addedProducts }));
                    }

                    if ((settings as LoadedSettings).basket.redirectOnAdd && routeName !== RouteName.BasketPage)
                        actions.push(navigateTo(routesBuilder.forBasket()));

                    return actions;
                }),
                catchError(
                    e => concat(of(basketUpdated(updatedById, date), basketChangeCompleted(0)), throwError(e)),
                ),
                retryWithToast(action$, logger),
                concatToIfEmpty(of(basketUpdated(updatedById, date), basketChangeCompleted(0))),
                startWith(basketChangeStarted()),
            );
        }),
    );

    // Currently BASKET_RECEIVED can be dispatched in the same time as NAVIGATED
    // so add some delay before start listening NAVIGATED to ignore the one dispatched right after BASKET_RECEIVED.
    const navigated$ = timer(50).pipe(
        mergeMapTo(action$),
        ofType(NAVIGATED),
    );

    const triggerNonOrderableRemoval$ = action$.pipe(
        ofType(BASKET_RECEIVED),
        switchMapTo(
            state$.pipe(
                first(),
                filter(({ basket }) => !!(basket.model && isBasketModel(basket.model) && basket.model.nonOrderableLines?.length)),
                delay(2000),
                mergeMap(_ => api.graphApi(clearNonOrderablesMutation).pipe(
                    ignoreElements(),
                    catchError(e => {
                        logger.error(e);
                        return EMPTY;
                    }),
                )),
                takeUntil(navigated$),
            ),
        ),
    );

    const startWithBasketChange = startWith<Action>(setLoadingIndicator(), basketChangeStarted()),
        hideIndicatorAction = unsetLoadingIndicator(),
        retryAndHideIndicator = retryWithToast(action$, logger, _ => of(hideIndicatorAction));

    const load$ = action$.pipe(
        ofType(BASKET_PAGE_REQUESTED),
        map(action => {
            const params = action.payload;
            if ('index' in params)
                return params;

            const model = state$.value.basket.model;
            return {
                ...params,
                index: model && isBasketModel(model) ? model.page.index : 0,
            };
        }),
        map(params => isTrackingEnabled(state$.value) ? { ...params, loadCategories: true } : params),
        switchMap(params => {
            if (state$.value.basket.syncBasket) {
                const { salesAgreementInfo, ...syncBasket } = state$.value.basket.syncBasket;
                if (isBasketModel(syncBasket) && syncBasket.page.index === params.index)
                    return of(basketReceived(syncBasket, salesAgreementInfo, params.index));
            }

            return api.graphApi<BasketDetailsResponse>(basketDetailsQuery, params).pipe(
                mergeMap(({ basket }) => {
                    const index = params.index;
                    const correctedPageIndex = getCorrectPageIndex(params.index, pageSize, basket.productLines.totalCount);

                    const redirect = correctedPageIndex !== index;
                    if (redirect)
                        return of(redirectToPage(state$.value.routing.location!.pathname, correctedPageIndex, true));

                    const { salesAgreementInfo, ...receivedBasket } = basket;
                    return of(hideIndicatorAction, basketReceived(receivedBasket, salesAgreementInfo, index));
                }),
                takeUntil(navigated$),
                retryAndHideIndicator,
                startWith(setLoadingIndicator()),
            );
        }),
    );

    const clear$ = action$.pipe(
        ofType(BASKET_CLEAR),
        skipIfPreviewWithToast(state$, dependencies),
        switchMap(action => {
            const date = Date.now();
            return api.graphApi<DeleteBasketResponse | ClearBasketResponse>(
                action.payload.remove ? deleteBasketMutation : clearBasketMutation,
                { requestModifiedLines: isTrackingEnabled(state$.value) },
            ).pipe(
                mergeMap(({ basket: basketResult }) => {
                    const basket = state$.value.basket.model;
                    const id = (action.payload.remove || !basket || isQuickOrderModel(basket)) ? '' : basket.id;
                    const newBasket: EmptyModel = { id, productLines: {}, totalCount: 0, cleared: true };

                    let salesAgreementInfo: SalesAgreementInfo | undefined;
                    if (state$.value.basket.salesAgreementInfo) {
                        salesAgreementInfo = {
                            ...state$.value.basket.salesAgreementInfo,
                            isAppliedToLines: false,
                        };
                    }

                    if (!action.payload.remove && basket && !isQuickOrderModel(basket)) {
                        newBasket.editDocumentId = basket.editDocumentId;
                        newBasket.editDocumentType = basket.editDocumentType;
                    }

                    const actions: Array<Action> = [
                        hideIndicatorAction,
                        basketUpdated(Updaters.Basket, date),
                        basketChangeCompleted(0),
                        basketReceived(newBasket, salesAgreementInfo),
                        setErrorMode(false),
                    ];

                    if ('empty' in basketResult && basketResult.empty.modifiedLines?.list.length) {
                        const productsForTracking = getProductsTrackingDataFromLines(basketResult.empty.modifiedLines.list);
                        if (productsForTracking.length)
                            actions.push(trackRemoveFromBasket({ products: productsForTracking }));
                    }

                    return actions;
                }),
                takeUntil(navigated$),
                retryAndHideIndicator,
                startWithBasketChange,
            );
        }),
    );

  const reset$ = action$.pipe(ofType(BASKET_UPDATED, BASKET_UPDATE, BASKET_ADD_PRODUCTS));
  const basketSummary$ = action$.pipe(
    ofType(BASKET_SUMMARY_REQUESTED),
    switchMap(({ payload: { calculated } }) => timer(50).pipe(
      mergeMap(_ => requestAbility(AbilityTo.ViewUnitOfMeasure, state$, { api } as StoreDependencies).pipe(
        mergeMap(abilityState => {
          const shouldLoadTrackingData = isTrackingEnabled(state$.value);

          const mapToBasketSummary = map<BasketSummaryResponse, Action>(({ basket }) => {
            if (!basket)
              return basketSummaryReceived(basket);

            const { salesAgreementInfo, modifiedDate, basketSpecialOrderType, ...summaryData } = basket;
            const summary = calculated != null ? { ...summaryData, calculated } : summaryData;
            return basketSummaryReceived(summary, salesAgreementInfo, modifiedDate, basketSpecialOrderType);
          });

          return api.graphApi(basketSummaryQuery, {
            sorting: 'RECENT',
            calculated,
            loadCategories: shouldLoadTrackingData,
            loadUoms: shouldLoadTrackingData || abilityState === AbilityState.Available,
          }).pipe(
            mapToBasketSummary,
            catchBasketCalculationError(error => {
              logBasketRetrievalError(error);

              return calculated
                ? api.graphApi(basketSummaryQuery, { sorting: 'RECENT', calculated: false }).pipe(mapToBasketSummary)
                : throwError(error);
            }),
            retryWithToast(action$, logger),
          );
        }),
      )),
      takeUntil(reset$),
    )),
  );

  const modifyBasket$ = action$.pipe(
    ofType(BASKET_UPDATE),
    pluck('payload'),
    switchMap(payload => {
      if ('writeOnly' in payload && payload.writeOnly)
        return saveLinesOnly(payload.modified, api, state$);

      const variables: Parameters<typeof getUpdateQuery>[0] = {};

      const trackingEnabled = isTrackingEnabled(state$.value);
      if (trackingEnabled)
        variables.loadCategories = true;

      const modified = payload.modified;
      if (modified?.length) {
        variables.data = { modified };
        variables.requestModifiedLines = trackingEnabled;
      }

      let index: number | undefined;
      if ('index' in payload && payload.index != null)
        index = payload.index;
      else if (state$.value.basket.model && isBasketModel(state$.value.basket.model))
        index = state$.value.basket.model.page.index;

      if (index != null)
        variables.index = index;

      if ('code' in payload) {
        const { code, countSubLines } = payload;
        variables.countSubLines = countSubLines;
        if (code != null)
          variables.code = code;
      }

      const query = getUpdateQuery(variables) || basketDetailsQuery;

      const mapBasketActions = mergeMap((result: BasketUpdateResponse['basket']) => {
        const basket = getBasketFromUpdateResult(result);
        const modifiedLines = 'update' in result ? result.update.modifiedLines?.list : null;
        const actions: Array<Action> = [
          basketUpdated(Updaters.Basket, +new Date(basket.modifiedDate!)),
        ];
        const correctedPageIndex = getCorrectPageIndex(index, pageSize, basket.productLines.totalCount);
        const redirect = correctedPageIndex !== index;

        if (modifiedLines?.length) {
          const basketLines = state$.value.basket.model!.productLines.list!;
          const { addedProducts, removedProducts } = getModifiedProductsTrackingData(basketLines, modifiedLines);

          if (addedProducts.length)
            actions.push(trackAddToBasket({ products: addedProducts }));

          if (removedProducts.length)
            actions.push(trackRemoveFromBasket({ products: removedProducts }));
        }

        if (modified)
          actions.push(basketChangeCompleted(modified.length));

        if (redirect) {
          actions.push(redirectToPage(state$.value.routing.location!.pathname, correctedPageIndex, true));
        } else {
          const { salesAgreementInfo, ...receivedBasket } = basket;
          actions.unshift(hideIndicatorAction);
          actions.push(basketReceived(receivedBasket, salesAgreementInfo, index));
        }

        return actions;
      });

      return api.graphApi<BasketUpdateResponse>(query, variables).pipe(
        pluck('basket'),
        mapBasketActions,
        takeUntil(navigated$),
        catchBasketCalculationError(error => {
          if (state$.value.routing.routeData?.routeName !== RouteName.BasketPage)
            return throwError(error);

          logBasketRetrievalError(error);
          return of(setErrorMode(true), unsetLoadingIndicator());
        }),
        retryAndHideIndicator,
        startWithBasketChange,
      );
    }),
  );

  const salesAgreementLinesRequest$ = action$.pipe(
    ofType(BASKET_AGREEMENT_LINES_REQUESTED),
    pluck('payload'),
    switchMap(({ agreementId, productId, basketLineId }) => api.graphApi<SalesAgreementsLinesResponse>(salesAgreementQuery, { agreementId, productIds: [productId] }).pipe(
      map(({ salesAgreements }) => salesAgreements?.agreement?.lines),
      mergeMap(lines => {
        const actions: Array<Action> = [unsetLoadingIndicator()];
        if (lines != null)
          actions.unshift(receiveAgreementLines(lines, basketLineId));

        return actions;
      }),
      startWith(setLoadingIndicator()),
    )),
  );

  return merge(
    basketAdd$,
    triggerNonOrderableRemoval$,
    load$,
    basketSummary$,
    modifyBasket$,
    clear$,
    salesAgreementLinesRequest$,
    orderTypeUpdate$,
    orderTypePopup,
  );
};

export default epic;

function getBasketFromUpdateResult(result: BasketUpdateResponse['basket']) {
  if ('addCoupon' in result) {
    return result.addCoupon.basket;
  } else if ('update' in result) {
    return result.update.basket;
  }

  return result;
}

function saveLinesOnly(modified: ModifiedLine[], api: Api, state$: StateObservable<AppState>) {
  const variables = { data: { modified }, requestModifiedLines: isTrackingEnabled(state$.value) };
  const date = Date.now();

  return api.graphApi<SaveLinesOnlyResponse>(saveLinesOnlyMutation, variables).pipe(
    mergeMap(({ basket }) => {
      const actions: Array<Action> = [
        basketUpdated(Updaters.Basket, date),
        basketChangeCompleted(modified.length),
      ];

      const modifiedLines = basket.update.modifiedLines?.list;
      if (modifiedLines?.length) {
        const basketLines = state$.value.basket.model!.productLines.list!;
        const { addedProducts, removedProducts } = getModifiedProductsTrackingData(basketLines, modifiedLines);

        if (addedProducts.length)
          actions.push(trackAddToBasket({ products: addedProducts }));

        if (removedProducts.length)
          actions.push(trackRemoveFromBasket({ products: removedProducts }));
      }

      return actions;
    }),
    catchError(_ => of(basketUpdated(Updaters.Basket, date), basketChangeCompleted(0))),
    concatToIfEmpty(of(basketUpdated(Updaters.Basket, date), basketChangeCompleted(0))),
  );
}

function isTrackingEnabled(state: AppState) {
  return !!state.analytics?.isTrackingEnabled;
}

type AddProductsResponse = {
  basket: {
    addProducts: {
      modifiedLines?: ModifiedLines | null;
    };
  };
};

type ApplySalesAgreementAndAddProductsResponse = {
  salesAgreement: {
    apply: {
      success: boolean;
    };
  };
  basket: {
    addProducts: {
      modifiedLines?: ModifiedLines | null;
    };
  };
};

type BasketDetailsResponse = {
  basket: Mandatory<ReceivedBasket, 'isAvailable'> & {
    salesAgreementInfo: SalesAgreementInfoData | null;
  };
};

type DeleteBasketResponse = {
  basket: {
    delete: Record<string, never>;
  };
};

type ClearBasketResponse = {
  basket: {
    empty: {
      modifiedLines?: ModifiedLines | null;
    };
  };
};

type BasketSummaryResponse = {
  basket: (Omit<ReceivedSummary, 'calculated'> & {
    modifiedDate: string | null;
    salesAgreementInfo: SalesAgreementInfoData | null;
    basketSpecialOrderType: any;
  }) | null;
};

type BasketSpecialOrderType = {
    basket: {      
        basketSpecialOrderType: any;
    }
};

type BasketUpdate = Omit<ReceivedBasket, 'isAvailable'> & {
  salesAgreementInfo: SalesAgreementInfoData | null;
};

type ModifyAndAddCouponResponse = {
  basket: {
    update: {
      modifiedLines?: ModifiedLines | null;
    };
    addCoupon: {
      basket: BasketUpdate;
    };
  };
};

type ModifyLinesResponse = {
  basket: {
    update: {
      basket: BasketUpdate;
      modifiedLines?: ModifiedLines | null;
    };
  };
};

type AddCouponResponse = {
  basket: {
    addCoupon: {
      basket: BasketUpdate;
    };
  };
};

type BasketUpdateResponse = ModifyAndAddCouponResponse | ModifyLinesResponse | AddCouponResponse | BasketDetailsResponse;

type SalesAgreementsLinesResponse = {
  salesAgreements: {
    agreement: {
      lines: Array<{
        id: string;
        discountPercent: number | null;
        price: number | null;
        isMaxEnforced: boolean;
        uom: {
          id: string;
          description: string | null;
        } | null;
        quantities: {
          commitment: number | null;
          remaining: number | null;
        };
        amounts: {
          remaining: number | null;
        };
        location: {
          code: string | null;
          title: string | null;
        };
      }>;
    } | null;
  } | null;
};

type SaveLinesOnlyResponse = {
  basket: {
    update: {
      modifiedLines?: ModifiedLines | null;
    };
  };
};

import type { PageTemplatesState } from './types';
import type { PageTemplateAction } from './actions';
import type { UserAuthenticatedAction } from 'behavior/user';
import { USER_AUTHENTICATED } from 'behavior/user';
import { PRODUCT_PAGE_TEMPLATE_LOADED } from './actions';
import { parseContent } from 'behavior/content';

export default (state: PageTemplatesState = {}, action: PageTemplateAction | UserAuthenticatedAction): PageTemplatesState => {
  switch (action.type) {
    case PRODUCT_PAGE_TEMPLATE_LOADED:
      const { page: { desktop, mobile, preset }, languageId } = action.payload;
      return {
        ...state,
        product: {
          desktop: desktop ? parseContent(desktop) : null,
          mobile: mobile ? parseContent(mobile) : null,
          languageId,
          preset,
        },
      };
    case USER_AUTHENTICATED:
      if (!state.product)
        return state;

      return {
        ...state,
        product: {
          ...state.product,
          expired: true,
        },
      };
    default:
      return state;
  }
};

import styles from './ProductGroup.module.scss';
import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { connectToContext } from 'utils/react';
import ProductGroupContext from './ProductGroupContext';
import { UomSelector, useCanViewUom, QuantityTextBox, createQuantityModel } from 'components/primitives/product';
import { requestCalculatedFields } from 'behavior/pages/productGroup';
import { addProducts } from 'behavior/basket';
import { addToBasket } from './AddToBasketButton';
import { getNumberDecimalsSeparator } from 'utils/format';
import { sanitizeId } from 'utils/helpers';

const QuantityBox = ({
  canOrderProducts,
  allowUOMSelection,
  updateQuantity,
  product,
  quantities,
  quantityValue,
  addProducts,
  resetQuantities,
  requestCalculatedFields,
  culture,
  groupId,
}) => {
  const { uoms, id: productId, isOrderable } = product;

  const canViewUom = useCanViewUom();
  const [uomId, updateUomId] = useState(product.uom && product.uom.id);
  const showUomSelector = canViewUom && uomId && uoms && uoms.length > 0;
  const quantityInfo = { value: 1, isValid: true };

  const updateUom = useCallback(uomId => {
    updateUomId(uomId);
    const options = { ids: [productId], uomId };
    updateQuantity(productId, uomId, null);
    requestCalculatedFields(groupId, options);
  }, [productId, updateQuantity, requestCalculatedFields]);

  const quantityModel = useMemo(() => {
    const uom = uoms && uoms.find(u => u.id === uomId);

    return createQuantityModel(uom, false);
  }, [uomId]);

  const handleQuantityChange = useCallback(quantity => {
    updateQuantity(productId, uomId, quantity);
  }, [uomId]);

  const handleEnter = useCallback(({ which, key }) => {
    if (key === 'Enter' || which === 13)
      addToBasket(quantities, resetQuantities, addProducts);
  }, [quantities, resetQuantities, addProducts]);

  const separator = getNumberDecimalsSeparator(culture);

  return (
    <>
      {isOrderable &&
        <div className={styles.quantityBox}>
          {canOrderProducts && (
            <div className={showUomSelector ? styles.quantityTextBox : styles.quantityTextBoxNoMargin}>
              {quantityModel && (
                <QuantityTextBox
                  id={`${sanitizeId(productId)}_qty`}
                  quantity={quantityModel}
                  value={quantityValue === undefined ? (quantityInfo && quantityInfo.value) : quantityValue}
                  onChange={handleQuantityChange}
                  onKeyDown={handleEnter}
                  separator={separator}
                  allowResetOnStepDecrease
                />
              )}
            </div>
          )}
          {showUomSelector && (
            <div className={styles.uomSelector}>
              <UomSelector
                productId={productId}
                allowUOMSelection={allowUOMSelection}
                uomId={uomId}
                uoms={uoms}
                onUomChange={updateUom}
              />
            </div>
          )}
        </div>
      }
    </>
  );
};

QuantityBox.propTypes = {
  canOrderProducts: PropTypes.bool.isRequired,
  allowUOMSelection: PropTypes.bool.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  product: PropTypes.shape({
    uoms: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool.isRequired,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  quantities: PropTypes.instanceOf(Map).isRequired,
  quantityValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addProducts: PropTypes.func.isRequired,
  resetQuantities: PropTypes.func.isRequired,
  requestCalculatedFields: PropTypes.func.isRequired,
  culture: PropTypes.string,
  groupId: PropTypes.string.isRequired,
};

const connectedQuantityBox = connect(
  ({ settings, localization, page: { id: groupId } }) => ({
    allowUOMSelection: settings.product && settings.product.allowUOMSelection,
    culture: localization.currentLanguage && localization.currentLanguage.cultureName,
    groupId,
  }),
  { requestCalculatedFields, addProducts },
)(QuantityBox);

export default connectToContext([ProductGroupContext], ({ quantities, updateQuantity, resetQuantities }, { product }) => {
  const productQuantity = quantities.get(product.id);

  return {
    quantities,
    quantityValue: productQuantity && productQuantity.quantity && productQuantity.quantity.value,
    updateQuantity,
    resetQuantities,
  };
})(connectedQuantityBox);

import type { Context, FunctionComponent } from 'react';
import { createElement, useContext } from 'react';
import hoistStatics from 'hoist-non-react-statics';

export function connectToContext<TOwnProps, TContextProps, T1>(
  contexts: [Context<T1>],
  mapContextToProps: (context1: T1, ownProps: TOwnProps) => TContextProps
): (component: FunctionComponent<TOwnProps & TContextProps>) => FunctionComponent<TOwnProps>;

export function connectToContext<TOwnProps, TContextProps, T1, T2>(
  contexts: [Context<T1>, Context<T2>],
  mapContextToProps: (context1: T1, context2: T2, ownProps: TOwnProps) => TContextProps
): (component: FunctionComponent<TOwnProps & TContextProps>) => FunctionComponent<TOwnProps>;

export function connectToContext<TOwnProps, TContextProps, T1, T2, T3>(
  contexts: [Context<T1>, Context<T2>, Context<T3>],
  mapContextToProps: (context1: T1, context2: T2, context3: T3, ownProps: TOwnProps) => TContextProps
): (component: FunctionComponent<TOwnProps & TContextProps>) => FunctionComponent<TOwnProps>;

export function connectToContext<TOwnProps, TContextProps>(
  contexts: Context<any>[],
  mapContextToProps: (...args: any[]) => TContextProps,
): (component: FunctionComponent<TOwnProps & TContextProps>) => FunctionComponent<TOwnProps> {
  return function (component: FunctionComponent<TOwnProps & TContextProps>) {
    if (process.env.NODE_ENV === 'development') {
      const isMemo = (component as any).$$typeof === Symbol.for('react.memo');
      if (!isMemo) {
        console.warn(`'connectToContext' should be used with memoized component ${component.displayName || component.name}.`);
      }
    }

    const connectedComponent = (props: TOwnProps) => {
      const resolvedContexts = [];

      for (const context of contexts)
        resolvedContexts.push(useContext(context));

      const contextProps = mapContextToProps(...resolvedContexts, props);

      return createElement(component, { ...contextProps, ...props });
    };

    connectedComponent.displayName = `ConnectToContext(${component.displayName || component.name || 'Component'})`;

    return hoistStatics(connectedComponent, component);
  };
}

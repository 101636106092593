import styles from './ProductGroup.module.scss';
import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { ProductPrice } from 'components/primitives/product';
import { Combobox, Select } from 'components/primitives/form';
import PromisedDeliveryDate from './StockAvailability/promisedDeliveryDate';
import ExtendedAddToBasketButton from './ExtendedAddToBasketButton';
import ExtendedStock from './ExtendedStock';
import { requestStockAvailability, requestVariantWisePrice } from 'behavior/pages/product';
import { connect, useDispatch } from 'react-redux';
import { useIsMobileViewport,useIsMiddleViewPort } from 'utils/hooks';
import { SimpleText, useSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { specialOrderPopup } from 'behavior/basket';
import { RichText } from 'components/sanaText';
import QuantityBox from './QuantityBox';

const ProductGroupTableRow = ({ product, variantWisePrice,customLengths, specificationHeaders, isOrderTypeAdded, weddingDate, orderType, specialOrderPopup,isOffLineMode,offLinemodeSupport }) => {
  const dispatch = useDispatch();
  const [selectedVariant, setSelectedVariant] = useState('');
  const [selectedCustomLength, setSelectedCustomLength] = useState('');
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState('');
  const [selectedDeliverDate, setSelectedDeliverDate] = useState('');
  const viewOnly = 'VIEW_ONLY';

  const [selectedVariantUnitPrice, setSelectedUnitVariantePrice] =  useState(product.price);
  const [selectedVariantListPrice, setSelectedListVariantePrice] =  useState(product.listPrice);
  const productLoadingState = useState(false);
  const [productLoading, setProductLoading] = productLoadingState;

  const [canViewStock, showPrice, canOrderProducts] = useHasAbilities(AbilityTo.ViewStock, AbilityTo.ViewPrices, AbilityTo.OrderProducts);

  const [productGroupingColorSizePlaceholderText, productGroupingCustomLengthPlaceholderText] = useSanaTexts(['ProductGrouping_ColorSize_PlaceholderText', 'ProductGrouping_CustomLength_PlaceholderText']).texts;

  useEffect(()=>{
    if (selectedVariant === undefined ||selectedVariant.length ===0 || variantWisePrice === undefined || variantWisePrice.length === 0) {
      if(product.price === undefined){
        return;
      }
      else{
        setSelectedUnitVariantePrice(product.price);
        setSelectedListVariantePrice(product.listPrice);
      }

    }
  },[product]);

  useEffect(() => {
    if (selectedVariant === undefined || selectedVariant.length ===0 || variantWisePrice === undefined || variantWisePrice.length === 0) {
      setProductLoading(false);
      return;
    }

    const variantFiltered = variantWisePrice.filter(x=> x.variantId===selectedVariant);
    if(variantFiltered.length ===0)
      return;

    setSelectedUnitVariantePrice(variantFiltered[0].unitPrice);
    setSelectedListVariantePrice(variantFiltered[0].listPrice);
    setProductLoading(false);

  }, [selectedVariant,variantWisePrice]);

  const getVariantDisplayTitle = title => {
    const splitArray = title.split('+');
    return splitArray.length > 0 ? title.replace(splitArray[0] + '+', '') : title;
  };

  const handleSelectedVariant = value => {

    setSelectedVariant(value);
    if(isOffLineMode && offLinemodeSupport === viewOnly){
      return;
    }
    else{
      loadAvailableStock(value);
      loadVariantWisePrice(value);
    }

    setProductLoading(true);
  };

  const handleSelectVariantComboBoxClick = () => {

    if (!isOrderTypeAdded || !orderType || (orderType === 'Bride' && !weddingDate)) {
      specialOrderPopup();
      setSelectedVariant('');
      return;
    }
  };

  const handleSelectedCustomLength = value => {
    setSelectedCustomLength(value);
    loadAvailableStock(value, false, true);
  };

  const handlePpdateDeliveryDetails = value => {
    if (value) {
      setSelectedDeliveryMethod(value.method);
      setSelectedDeliverDate(value.date);
    } else {
      setSelectedDeliveryMethod('');
      setSelectedDeliverDate('');
    }
  };

  const loadAvailableStock = (value, changeVariant = true, changeCustomLength = false) => {
    if (changeVariant) {
      if (product && product.hasVariants && value)
        dispatch(requestStockAvailability({ productId: product.id, uom: product.uom.id, variantId: value, customLength: selectedCustomLength, weddingDate: weddingDate ? new Date(weddingDate).toISOString() : undefined }));
    }
    else if (changeCustomLength && selectedVariant)
      dispatch(requestStockAvailability({ productId: product.id, uom: product.uom.id, variantId: selectedVariant, customLength: value, weddingDate: weddingDate ? new Date(weddingDate).toISOString() : undefined }));
  };

  const loadVariantWisePrice = value => {
    dispatch(requestVariantWisePrice({ productId: product.id, uom: product.uom.id, variantId: value }));
  };

  const isMobile = useIsMobileViewport();
  const isMiddel = useIsMiddleViewPort();
  const tableHeadingPlaceholder = <Placeholder className={styles.tableHeadingPlaceholder} />;

  return (
    <tr className={`${!isMobile && !isMiddel ? null : 'mobile-table-tr'} variant-table`} key={product.id} >
      <td className={`${!isMobile  && !isMiddel ? null : 'mobile-table-row'} item-no`}>
        <div className={!isMobile && !isMiddel ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="ProductGrouping_VariantsTab_IdHeader" placeholder={tableHeadingPlaceholder} /></div>
        <span className={styles.productId} title={product.title}>
          {product.id}
        </span>
      </td>
      <td className={`${!isMobile ? isMiddel ? 'mobile-table-row' : 'color-size' : 'mobile-table-row'}`}>
        <div className={!isMobile && !isMiddel ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="ProductGrouping_VariantsTab_ColorSizeHeader" children="Color + Size" placeholder={tableHeadingPlaceholder} /></div>
        {product && product.variants && product.variants.length > 0
          &&
          <div key={product.id} className={!isMobile && !isMiddel ? '' : ` ${styles.colorSizeDropDownMobile}`}>
            <Combobox
              id={'vcb_' + product.id}
              className={styles.variantcombobox}
              items={[...[{ name: productGroupingColorSizePlaceholderText, value: undefined }], ...product.variants.map(c => ({ name: getVariantDisplayTitle(c.title), value: c.id }))]}
              onChange={value => handleSelectedVariant(value.value)}
              onClick={() => handleSelectVariantComboBoxClick()}
              placeholderText={productGroupingColorSizePlaceholderText}
            />
          </div>
        }        
      </td>
      <td className={`${!isMobile &&!isMiddel ? null : 'mobile-table-row'+ (product && product.isDress &&!isMiddel ? '' :'')}  `}>
        {product && product.isDress && <>
          <div className={!isMobile && !isMiddel ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="ProductGrouping_VariantsTab_CustomLengthHeader" children="Custom Length" placeholder={tableHeadingPlaceholder} /></div>
          <div key={'customlength_' + product.id} className={!isMobile && !isMiddel ? '': ` ${styles.colorSizeDropDownMobile}`}>
            <Select
              className={styles.customLength}
              id={'cldp_' + product.id}
              items={[...[{ name: productGroupingCustomLengthPlaceholderText, value: undefined }], ...customLengths.map(c => ({ name: c.description, value: c.title }))]}
              optionClassName={styles.customLengths}
              disabled={false}
              onChange={value => handleSelectedCustomLength(value)}
              placeholderTextKey={'CustomLengthDropDown_PlaceholderKey'}
              placeholderText={'Select'}
            />
          </div>
        </>
        }
      </td>

      {canViewStock &&
        <td className={`${!isMobile &&!isMiddel ? null : 'mobile-table-row'}`}>
          <div className={!isMobile &&!isMiddel  ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="ProductGrouping_VariantsTab_inStockHeader" children="In-Stock" placeholder={tableHeadingPlaceholder} /></div>
          <ExtendedStock
            product={product}
            uom={product.uom.id}
            variantId={selectedVariant}
            customLength={selectedCustomLength}
          />
        </td>
      }
      <td className={`${!isMobile ? (isMiddel ? 'mobile-table-row avaliability': 'avaliability') : 'mobile-table-row avaliability'}`}>
        <div className={!isMobile  &&!isMiddel ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="ProductGrouping_VariantsTab_AvailabilityHeader" placeholder={tableHeadingPlaceholder} /></div>
        <PromisedDeliveryDate
          product={product}
          uom={product.uom.id}
          variantId={selectedVariant}
          customLength={selectedCustomLength}
          updateDeliveryDetails={handlePpdateDeliveryDetails}
          selectedDate = {selectedDeliverDate}
        />
      </td>
      {canOrderProducts &&

        <td className={`${!isMobile && !isMiddel ? null : 'mobile-table-row'}`}>
          <div className={!isMobile && !isMiddel ? 'visually-hidden' : 'mobile-table-name'}>
            <SimpleText textKey="ProductGrouping_VariantsTab_Quantity" placeholder={tableHeadingPlaceholder} />
          </div>
          <div>
            <QuantityBox canOrderProducts={canOrderProducts} product={product} />
          </div>
        </td>      
      }

      {showPrice &&
        productLoading
          ?  (
<td className={`${styles.productAction} ${styles.priceCell} ${!isMobile ? null : 'mobile-table-row'} price`}>
                <RichText textKey="StockText_Loading" children="Loading..." />
              </td>
) : (
<td className={`${styles.productAction} ${styles.priceCell} ${!isMobile ? isMiddel ? 'mobile-table-row': 'price' : 'mobile-table-row price'}`}>
          <div className={!isMobile &&!isMiddel ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="Price" placeholder={tableHeadingPlaceholder} /></div>
          <ProductPrice salesPrice={selectedVariantUnitPrice} basePrice={selectedVariantListPrice} />
        </td>
)}
      {showPrice &&
        <td className={`${styles.productAction} ${styles.priceCell} ${!isMobile ? isMiddel ? 'mobile-table-row': 'msrp' : 'mobile-table-row msrp'}`}>
        <div className={!isMobile &&!isMiddel ? 'visually-hidden' : 'mobile-table-name'}> <SimpleText textKey="MSRPPrice" children="MSRP" placeholder={tableHeadingPlaceholder} /></div>
          <ProductPrice salesPrice={product.mSRPPrice} basePrice={product.mSRPPrice} />
        </td>
      }
      {canOrderProducts &&
        <td className={`${styles.orderBoxCell} ${!isMobile &&!isMiddel ? null : 'mobile-table-row'}`}>
          <div className={!isMobile &&!isMiddel ? 'visually-hidden' : 'mobile-table-name'} />
          <ExtendedAddToBasketButton product={product} variantId={selectedVariant} customLength={selectedCustomLength} deliveryDate={selectedDeliverDate} deliveryType={selectedDeliveryMethod} />
        </td>
      }
      
    </tr>
  );
};

ProductGroupTableRow.propTypes = {
  variantWisePrice : PropTypes.shape({
    productId: PropTypes.string,
    uom: PropTypes.string,
    variantId: PropTypes.string,
    isOrderable: PropTypes.bool,
    unitPrice: PropTypes.number,
    listPrice: PropTypes.number,
    id: PropTypes.array,
  }),
  products: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    price: PropTypes.number,
    listPrice: PropTypes.number,
    specifications: PropTypes.array,
  }),
  customLengths: PropTypes.array,
  specificationHeaders: PropTypes.array,
  weddingDate: PropTypes.string,
  orderType: PropTypes.string,
  specialOrderPopup: PropTypes.func,
};

function mapStateToProps({ basket }) {
  const { basketSpecialOrderType } = basket;
  const orderType = basketSpecialOrderType?.basketSpecialOrderType;
  const weddingDate = basketSpecialOrderType?.basketSpecialOrderDate;
  return { weddingDate, orderType };
}

export default memo(connect(mapStateToProps, { specialOrderPopup })(ProductGroupTableRow));


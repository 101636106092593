import loadable from '@loadable/component';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { CheckoutProcessNames } from 'behavior/pages/checkout';
import createSystemRenderer from 'components/objects/systemPages/renderer';
import { Steps } from 'behavior/pages/checkout';
import { getPageTitleTextKey } from './helpers';

const Checkout = loadable(() => import(/*webpackChunkName:"checkout"*/'./Checkout'));
const OrderSubmit = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderSubmit'));
const OrderFailed = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderFailed'));
const OrderCancelled = loadable(() => import(/*webpackChunkName:"checkout"*/'./orderCancelled'));
const PaymentError = loadable(() => import(/*webpackChunkName:"checkout"*/'./paymentError'));

/* eslint-disable react/no-multi-comp */
function checkoutContentRenderer(page) {
  return <Checkout info={page.info} />;
}

export function checkoutRenderer(page) {
  const backTo = page.info.quote
    ? {
      texts: ['BackToQuote'],
      url: page.info.quote.url,
      route: routesBuilder.forDocument(page.info.quote.id, DocumentType.Quote),
    }
    : null;

  const headerTextKey = getPageTitleTextKey(!!page.info.quote, page.info.isQuote);
  const asDocumentTitle = page.overrideDocumentTitle || page.info.currentStep === Steps.None;
  return createSystemRenderer({ textKey: headerTextKey, asDocumentTitle }, checkoutContentRenderer, backTo)(page);
}

function orderSubmitContentRenderer(page) {
  return <OrderSubmit transaction={page.transaction} />;
}

export function orderSubmitRenderer(page) {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Quote || checkoutProcessName === CheckoutProcessNames.EditQuote
    ? 'SubmitQuote_QuoteProcessedSuccessfully'
    : checkoutProcessName === CheckoutProcessNames.Invoice
      ? 'SubmitInvoice_ProcessedSuccessfully'
      : 'SubmitOrder_OrderProcessedSuccessfully';

  return createSystemRenderer({ textKey: headerTextKey, asDocumentTitle: true }, orderSubmitContentRenderer)(page);
}

function orderFailedContentRenderer(page) {
  return <OrderFailed checkoutProcessName={page.transaction.checkoutProcessName} />;
}

export function orderFailedRenderer(page) {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Invoice
    ? 'InvoicePaymentFailed_Header'
    : 'OrderFailed_Header';

  return createSystemRenderer({ textKey: headerTextKey, asDocumentTitle: true }, orderFailedContentRenderer)(page);
}

function orderCancelledContentRenderer(page) {
  return (
    <OrderCancelled
      checkoutProcessName={page.transaction.checkoutProcessName}
      document={page.transaction.document}
      transactionId={page.transaction.id}
    />
  );
}

export function orderCancelledRenderer(page) {
  const { transaction: { checkoutProcessName } } = page;

  const headerTextKey = checkoutProcessName === CheckoutProcessNames.Invoice
    ? 'InvoiceCancelled_Header'
    : checkoutProcessName === CheckoutProcessNames.Promotion
      ? 'QuotePaymentCancelled_Header'
      : 'OrderCancelled_Header';

  return createSystemRenderer({ textKey: headerTextKey, asDocumentTitle: true }, orderCancelledContentRenderer)(page);
}

function paymentErrorPageRenderer(page) {
  return (
    <PaymentError
      transactionId={page.transaction.id}
      document={page.transaction.document}
      checkoutProcessName={page.transaction.checkoutProcessName}
    />
  );
}

export function paymentErrorRenderer(page) {
  return createSystemRenderer({ textKey: 'OrderPaymentError_Header' }, paymentErrorPageRenderer)(page);
}

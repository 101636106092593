import type { Product } from './types';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { createReducer } from 'utils/redux';
import {
  DOC_FREE_RETURN_ORDER_SUBMITTED,
  DOC_FREE_RETURN_ORDER_FAILED,
  DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED,
} from './actions';
import { arrayToObject } from 'utils/helpers';

export type State = {
  component: PageComponentNames.CreateDocFreeReturnOrder;
  products: Record<string, Product>;
  isFailed?: true;
};

export const initialState = { products: {} } as State;

export default createReducer(initialState, {
  [DOC_FREE_RETURN_ORDER_SUBMITTED]: state => ({ ...state, isFailed: undefined }),
  [DOC_FREE_RETURN_ORDER_FAILED]: state => ({ ...state, isFailed: true as const }),
  [DOC_FREE_RETURN_ORDER_PRODUCTS_RECEIVED]: (state, action) => ({
    ...state,
    products: { ...state.products, ...arrayToObject(action.payload.products, (product: Product) => product.id) },
  }),
});

import styles from './ProductGroup.module.scss';
import { RichText } from 'components/sanaText';

const SizeChartDownloadLink = () => {
  return (
    <div>
      <RichText textKey="SizeChartDownloadLink_Title" children="<div><h4><a href='#' target='_bank'>Size Chart</a></h4></div>" />
      <RichText textKey="SizeChartDownloadLink_Description" children="<div>Select the size to see the avaiabe dress modifications. Sizes are using American USA references.</div>" />
    </div>
  );
};

export default SizeChartDownloadLink;
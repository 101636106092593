import { memo } from 'react';
import PropTypes from 'prop-types';
import { useResponsiveBreakpoints } from 'utils/layout';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';

const DesktopMobileImage = ({
  desktopImage,
  mobileImage,
  alt,
  className,
  afterLoad,
  imageLoadVisibleByDefault,
}) => {
  const { xs } = useResponsiveBreakpoints();

  if (!xs && !desktopImage)
    return null;

  return (
    <ResponsiveLazyImage
      key={xs}
      src={xs ? mobileImage : desktopImage}
      className={className}
      afterLoad={afterLoad}
      visibleByDefault={imageLoadVisibleByDefault}
      alt={alt}
    />
  );
};

DesktopMobileImage.propTypes = {
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  afterLoad: PropTypes.func,
  imageLoadVisibleByDefault: PropTypes.bool,
};

export default memo(DesktopMobileImage);
import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SanaButton from './SanaButton';
import { LoadingIcon, ArrowDownIcon } from './Icons';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';

const LoadMoreButton = ({ textKey, className = '', totalCount, loadedCount, batchSize, loadNext, ...props }) => {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    return () => setTimeout(toggleOverflowAnchorState, 50);
  }, [loadedCount]);

  const nextIndex = loadedCount < totalCount &&
    Math.floor(loadedCount / batchSize);

  if (!nextIndex)
    return null;

  return (
    <SanaButton textKey={textKey}
      className={className}
      icon={isLoading ? <LoadingIcon /> : <ArrowDownIcon />}
      formatWith={[batchSize]}
      onClick={() => {
        setLoading(true);
        toggleOverflowAnchorState(true);
        loadNext(nextIndex);
      }}
      {...props}
    />
  );
};

LoadMoreButton.propTypes = {
  textKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  loadedCount: PropTypes.number.isRequired,
  batchSize: PropTypes.number.isRequired,
  loadNext: PropTypes.func.isRequired,
};

export default memo(LoadMoreButton);

import styles from './Summary.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { ProductThumbnail } from 'components/primitives/product';

const Thumbnail = ({ productTitle, imageUrl, productUrl, route, noImageUrl }) => {

  //Ticket 212102: [MadiLane] [Phase 2] Block links to "Product Pages"
  //if (productUrl) {
  //  return (
  //    <WithProductClickTracking>
  //      {handleClick => (
  //        <Link url={productUrl} to={route} className={styles.productImg} onClick={handleClick}>
  //          <ProductThumbnail src={imageUrl} noImageSrc={noImageUrl} title={productTitle} />
  //        </Link>
  //      )}
  //    </WithProductClickTracking>
  //  );
  //}
  //Ticket 212102: [MadiLane] [Phase 2] Block links to "Product Pages"

  return (
    <span className={styles.productImg}>
      <ProductThumbnail src={imageUrl} noImageSrc={noImageUrl} title={productTitle} />
    </span>
  );
};

Thumbnail.propTypes = {
  productTitle: PropTypes.string,
  imageUrl: PropTypes.string,
  productUrl: PropTypes.string,
  route: PropTypes.any.isRequired,
  noImageUrl: PropTypes.string,
};

export default connect(({ settings: { product } }) => ({
  noImageUrl: product ? product.noImage.small : null,
}))(Thumbnail);


import { Helmet } from 'react-helmet';
import { createBreadcrumbStructure, useTrackingOptions } from 'utils/structureData';
import renderOnce from 'components/objects/renderOnce';

type Props = {
  nodes: Parameters<typeof createBreadcrumbStructure>[0];
  homeText?: string | null;
  homePath?: string | null;
};

const Schema = ({ nodes, homePath, homeText }: Props) => {
  const options = useTrackingOptions();

  const schema = createBreadcrumbStructure(
    homeText && nodes 
      ? [{ title: homeText, link: { url: homePath } }, ...nodes] 
      : nodes, 
    options,
  );

  return schema && (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

export default renderOnce(Schema);

import { useEffect, useState } from 'react';
import { SanaButton } from 'components/primitives/buttons';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Radio } from 'components/primitives/form';
import { Row, Col } from 'components/primitives/grid';
import { toLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';
import { RichText } from 'components/sanaText';

const StockAvailabilityForm = ({ bridesname, weddingdate, stockAvailability, onConfirm,selectedDeliveryMethod,selectedDeliveryDate }) => {

  if (!stockAvailability)
    return;

  const culture = useCultureName();
  const [deliveryDates, setDeliveryDates] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState({method : selectedDeliveryMethod,date:selectedDeliveryDate});
  const [selectedOption, setSelectedOption] = useState('Standard');

  useEffect(() => {

    const radioOptions = [];
    if (stockAvailability.standard) {
      radioOptions.push({ name: 'Standard', value: 'Standard' });
    }
    if (stockAvailability.rush) {
      radioOptions.push({ name: 'Rush', value: 'Rush' });
    }
    if (stockAvailability.misc) {
      radioOptions.push({ name: 'Misc', value: 'Misc' });
    }
    if (stockAvailability.onHand) {
      radioOptions.push({ name: 'Stock', value: 'Stock' });
    }
    if (stockAvailability.preOrder) {
      radioOptions.push({ name: 'PreOrder', value: 'PreOrder' });
    }
    if (stockAvailability.quickShip) {
      radioOptions.push({ name: 'QuickShip', value: 'QuickShip' });
    }
    setDeliveryDates(radioOptions);
  }, [stockAvailability]);

  useEffect(() => {
    if(selectedDeliveryMethod !== undefined)
      setSelectedOption(selectedDeliveryMethod);
    else
      return;

  }, [selectedDeliveryMethod]);

  const getStockDeliveryDate = name => {
    if (name.toLowerCase() === 'standard')
      return stockAvailability.standard;
    else if (name.toLowerCase() === 'rush')
      return stockAvailability.rush;
    else if (name.toLowerCase() === 'misc')
      return stockAvailability.misc;
    else if (name.toLowerCase() === 'stock')
      return stockAvailability.onHand;
    else if (name.toLowerCase() === 'preorder')
      return stockAvailability.preOrder;
    else if (name.toLowerCase() === 'quickship')
      return stockAvailability.quickShip;
    else
      return null;
  };

  const onChange = value => {
    setSelectedOption(value.method)
    setSelectedDelivery(value);
  };

  return (
    <>
      <div className="select-delivery-popup">
        <Row>
          <Col>
            <h2>
              <SimpleText textKey="StockAvailablity_Popup_Title" children="Select the delivery option" />
            </h2>
          </Col>
        </Row>
        {weddingdate &&
          <>
            <Row>
              <Col>
                <SimpleText textKey="StockAvailablity_Popup_BridesName" children="Bride's Name" />
              </Col>
              <Col>
                <span>{bridesname}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <SimpleText textKey="StockAvailablity_Popup_WeddingDate" children="Wedding Date" />
              </Col>
              <Col>
                <span>{toLocaleDate(weddingdate, culture)}</span>
              </Col>
            </Row>
          </>
        }
      </div>
      {/*Ticket 229366: [MadiLane] Standard Date*/}
      <div className="select-delivery-popup">
        {
          deliveryDates.map(item => (
            <Row key={`${stockAvailability.productId}_${item.name}_${item.name}`}>
              <Col>
                <Radio
                  key={`${stockAvailability.productId}_${item.name}`}
                  id={`${stockAvailability.productId}_${item.name}`}
                  name={`stockAvailability_${stockAvailability.productId}`}
                  value={item.value}
                  checked={item.value === selectedOption }
                  onChange={onChange && (() => onChange({ method: item.value, date: getStockDeliveryDate(item.name) }))}
                >
                  <RichText textKey={`StockAvailablity_Popup_${item.name}`} children={item.name} />
                </Radio>
              </Col>
              <Col>
                <time
                  dateTime={getStockDeliveryDate(item.name)}>
                    {toLocaleDate(getStockDeliveryDate(item.name), culture)}
                </time>
              </Col>
            </Row>
          ))
        }
        {weddingdate && deliveryDates && deliveryDates.length === 0 &&
          <RichText textKey="StockAvailablity_Popup_Bridal_NoDatesAvailable" children="We do not have a dress/ an item for selected wedding date." />
        }
        {!weddingdate && deliveryDates && deliveryDates.length === 0 &&
          <RichText textKey="StockAvailablity_Popup_Stock_NoDatesAvailable" children="We do not have this item/ dress available in stock." />
        }

        <Row>
          <Col>
            <SanaButton textKey="StockAvailablity_Popup_Confirm"
              defaultText="Confirm"
              theme="action"
              onClick={onConfirm && (() => onConfirm(selectedDelivery))}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

StockAvailabilityForm.propTypes = {
  bridesname: PropTypes.string.isRequired,
  weddingdate: PropTypes.string.isRequired,
  stockAvailability: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
};

export default StockAvailabilityForm;
import styles from './ProductGroup.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { Col, Row } from 'components/primitives/grid';
import PropTypes from 'prop-types';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { Placeholder } from 'components/primitives/placeholders';
import useEditableSpecifications from '../product/useEditableSpecifications';
import { getSpecificationHeaders } from './util';
import { sanitizeId } from 'utils/helpers';
import ProductGroupTableRow from './ProductGroupTableRow';
import { useIsMobileViewport,useIsMiddleViewPort } from 'utils/hooks';
import product from '../product';

const ProductGroupTabs = ({ products: productsInput, customLengths, isOrderTypeAdded }) => {

  const [canViewStock, showPrice, canOrderProducts] = useHasAbilities(AbilityTo.ViewStock, AbilityTo.ViewPrices, AbilityTo.OrderProducts);
  const tableHeadingPlaceholder = <Placeholder className={styles.tableHeadingPlaceholder} />;
  const variantWisePrice= useSelector(state => state.page.variantWisePrice);
  const specifications = useSelector(state => state.settings.product.productGrouping.specifications);
  const specificationHeaders = getSpecificationHeaders(specifications || [], productsInput || []);
  const isOffLineMode =  useSelector(state => state.app.offlineMode);
  const offLinemodeSupport = useSelector(state => state.app.offlineModeSupport);

  // const isDress = products & products.length > 0 ? products[0].isDress : false;

  const products = productsInput.map(product => ({
    ...product,
    // , specifications: useEditableSpecifications(product.specifications),
  }));

  const isMobile = useIsMobileViewport();
  const isMiddel = useIsMiddleViewPort();

  return (
    <Row className="print-no-flex">
      <Col xs={12} lg={12} xl={12}>
        <h2>
          <SimpleText textKey="ProductGroup_VariantsTabTitle" placeholder={tableHeadingPlaceholder} />
        </h2>
        <div className={styles.container}>
          <table className={`${tableStyles.table} ${styles.variantsTable} `}>
            <thead className={`${!isMobile  && !isMiddel ? '' : 'visually-hidden'}`}>
              <tr>
                <th id="headerId">
                  <SimpleText textKey="ProductGrouping_VariantsTab_IdHeader" placeholder={tableHeadingPlaceholder} />
                </th>
                <th id="colorSize">
                  <SimpleText textKey="ProductGrouping_VariantsTab_ColorSizeHeader" children="Color + Size" placeholder={tableHeadingPlaceholder} />
                </th>
                <th id="customLength">
                  <SimpleText textKey="ProductGrouping_VariantsTab_CustomLengthHeader" children="Custom Length" placeholder={tableHeadingPlaceholder} />
                </th>
                {canViewStock &&
                  <th id="inStockIndication">
                    <SimpleText textKey="ProductGrouping_VariantsTab_inStockHeader" children="In-Stock" placeholder={tableHeadingPlaceholder} />
                  </th>
                }
                <th id="headerStockIndication">
                  <SimpleText textKey="ProductGrouping_VariantsTab_AvailabilityHeader" placeholder={tableHeadingPlaceholder} />
                </th>
                {canOrderProducts &&
                  <th id="HeaderQuantity">
                    <SimpleText textKey="ProductGrouping_VariantsTab_Quantity" placeholder={tableHeadingPlaceholder} />
                  </th>
                }                
                {showPrice &&
                  <th id="headerPrice" className={styles.priceCell}>
                    <SimpleText textKey="Price" placeholder={tableHeadingPlaceholder} />
                  </th>
                }
                {showPrice &&
                  <th id="headerPriceMSRP" className={styles.priceCell}><SimpleText textKey="MSRPPrice" children="MSRP" placeholder={tableHeadingPlaceholder} />
                  </th>}
                {canOrderProducts && <th />}
                             
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <ProductGroupTableRow key={product.id} variantWisePrice={variantWisePrice} product={product} customLengths={customLengths} specificationHeader={specificationHeaders} isOrderTypeAdded={isOrderTypeAdded} isOffLineMode={isOffLineMode} offLinemodeSupport={offLinemodeSupport} />
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </Row>
  );
};

ProductGroupTabs.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    price: PropTypes.number,
    listPrice: PropTypes.number,
    specifications: PropTypes.array,
  })),
  customLengths: PropTypes.array,
  isOrderTypeAdded: PropTypes.bool,
};

export default memo(ProductGroupTabs);
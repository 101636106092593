import type {
    Product as GeneralProduct,
    CalculatedProduct,
    VolumePrice,
    SalesAgreement,
    SalesAgreementLineAvailability,
    StockAvailabilityResponse,
    VariantWisePriceAvailabilityResponse,
} from './types';
import type { ViewerChangedAction } from 'behavior/events';
import type { PageComponentNames } from '../componentNames';
import {
    ProductCalculatedFieldsLoadedAction,
    ReviewsReceivedAction,
    ReviewProcessedAction,
    VolumePriceReceivedAction,
    SalesAgreementReceivedAction,
    ChangeProductVariantForSalesAgreementAction,
    requestedStockAvailabilityReceivedAction,
    requestStockAvailabilityAction,
    requestVariantWisePriceAction,
    PRODUCT_CALCULATED_FIELDS_LOADED,
    REVIEWS_RECEIVED,
    REVIEW_PROCESSED,
    SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED,
    SALES_AGREEMENT_RECEIVED,
    VOLUME_PRICES_RECEIVED,
    STOCK_AVAILABILITY_REQUESTED,
    REQUESTED_STOCK_AVAILABILITY_RECEIVED,
    VARIANT_WISE_PRICE_REQUESTED,
    variantWisePriceReceivedAction,
    VARIANT_WISE_PRICE_RECEIVED,
} from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';
import { deleteProductCalculatedInfo } from 'behavior/products/product';
import { Preset } from 'behavior/pages/product';

type Product = Omit<GeneralProduct, 'reviews'> & {
    reviews?: GeneralProduct['reviews'] & { saved?: number | null } | null;
};

type SalesAgreementLines = SalesAgreement['lines'];

type State = {
    component: PageComponentNames.Product;
    product: Product | Product & CalculatedProduct & { loaded: boolean };
    volumePrices?: { prices: VolumePrice[]; variantId?: string | null; uomId: string | null };
    preset: Preset;
    salesAgreement?: SalesAgreement & {
        variantId?: string;
        linesToDisplay: SalesAgreementLines | null;
        linesAvailability: SalesAgreementLineAvailability[] | null;
        preSelectedLine?: SalesAgreementLines[0] | null;
    };
    stockAvailability?: StockAvailabilityResponse[] | null;
    variantWisePrice?: VariantWisePriceAvailabilityResponse[] | null;
};

type Action =
    | ProductCalculatedFieldsLoadedAction
    | ReviewsReceivedAction
    | ReviewProcessedAction
    | VolumePriceReceivedAction
    | SalesAgreementReceivedAction
    | ChangeProductVariantForSalesAgreementAction
    | ViewerChangedAction
    | requestStockAvailabilityAction
    | requestedStockAvailabilityReceivedAction
    | requestVariantWisePriceAction
    | variantWisePriceReceivedAction;

export default createReducer<State, Action>(null as unknown as State, {
    [PRODUCT_CALCULATED_FIELDS_LOADED]: onProductLoaded,
    [REVIEWS_RECEIVED]: onReviewsReceived,
    [REVIEW_PROCESSED]: onReviewProcessed,
    [VOLUME_PRICES_RECEIVED]: onVolumePricesReceived,
    [VIEWER_CHANGED]: onViewerChanged,
    [SALES_AGREEMENT_RECEIVED]: onSalesAgreementReceived,
    [SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED]: onSalesAgreementVariantChanged,
    [STOCK_AVAILABILITY_REQUESTED]: onStockAvailabilityRequested,
    [REQUESTED_STOCK_AVAILABILITY_RECEIVED]: onStockAvailabilityReceived,
    [VARIANT_WISE_PRICE_REQUESTED]: onVariantWisePriceRequested,
    [VARIANT_WISE_PRICE_RECEIVED]: onVariantWisePriceReceived,
});

function onProductLoaded(state: State, action: ProductCalculatedFieldsLoadedAction): State {
    const stateVariants = state.product?.variants;
    const variants = action.payload.variants as Product['variants'] & CalculatedProduct['variants'];

    if (variants && stateVariants) {
        for (const variant of variants) {
            const stateVariant = stateVariants.find(v => v.id === variant.id);
            if (stateVariant)
                variant.bomComponents = stateVariant.bomComponents;
        }
    }

    return {
        ...state,
        product: {
            ...state.product as Product,
            ...action.payload,
            variants,
            loaded: true,
        },
    };
}

function onReviewsReceived(state: State, action: ReviewsReceivedAction): State {
    return {
        ...state,
        product: {
            ...state.product,
            reviews: {
                total: state.product.reviews!.total,
                avg: state.product.reviews!.avg,
                list: state.product.reviews!.list.concat(action.payload),
            },
        },
    };
}

function onReviewProcessed(state: State, action: ReviewProcessedAction): State {
    return {
        ...state,
        product: {
            ...state.product,
            reviews: {
                ...state.product.reviews!,
                saved: action.payload ? Date.now() : null,
            },
        },
    };
}

function onVolumePricesReceived(state: State, action: VolumePriceReceivedAction): State {
    return {
        ...state,
        volumePrices: action.payload,
    };
}

function onViewerChanged(state: State): State {
    if (!state.product)
        return state;

    const product = deleteProductCalculatedInfo(state.product as Product & CalculatedProduct);
    return { ...state, product };
}

function onSalesAgreementReceived(state: State, { payload }: SalesAgreementReceivedAction): State {
    const {
        agreement,
        linesAvailability,
        canViewUom,
        allowUomSelection,
        productUom,
        productUoms,
    } = payload;

    const variantId = state.salesAgreement?.variantId;
    let linesToDisplay: SalesAgreementLines | null = buildAgreementLinesToDisplay(agreement.lines, linesAvailability, state.preset, variantId);
    linesToDisplay = adjustAgreementLinesToProduct(linesToDisplay, canViewUom, allowUomSelection, productUom, productUoms);

    return {
        ...state,
        salesAgreement: {
            ...agreement,
            variantId,
            linesToDisplay,
            linesAvailability,
            preSelectedLine: linesToDisplay && getAgreementLineById(linesToDisplay, state.salesAgreement?.preSelectedLine?.id),
        },
    };
}

function onSalesAgreementVariantChanged(state: State, { payload: { variantId, canViewUom, allowUOMSelection } }: ChangeProductVariantForSalesAgreementAction): State {
    const agreement = state.salesAgreement || {
        lines: [],
        linesAvailability: [],
    } as unknown as State['salesAgreement'];

    let linesToDisplay: SalesAgreementLines | null = buildAgreementLinesToDisplay(agreement!.lines, agreement!.linesAvailability, state.preset, variantId);

    if (linesToDisplay.length > 0) {
        const productUom = state.product?.uom;
        const productUoms = state.product?.uoms;
        linesToDisplay = adjustAgreementLinesToProduct(linesToDisplay, canViewUom, allowUOMSelection, productUom, productUoms);
    }

    return {
        ...state,
        salesAgreement: {
            ...agreement!,
            variantId,
            linesToDisplay,
        },
    };
}

function buildAgreementLinesToDisplay(lines: SalesAgreementLines, linesAvailability: SalesAgreementLineAvailability[] | null, preset: Preset, variantId?: string) {
    if (!linesAvailability)
        return [];

    const filterByVariant = preset !== Preset.DetailsWithMatrix;

    return lines.filter(line => linesAvailability.some(availability =>
        availability.lineId === line.id
        && (!filterByVariant || (!availability.variantId || areEqualOrEmpty(variantId, availability.variantId)))
        && (!line.isMaxEnforced || line.quantities.remaining! > 0 || line.amounts.remaining! > 0),
    ));
}

function areEqualOrEmpty(value1: unknown, value2: unknown): boolean {
    return ((!value1 && !value2) || value1 === value2);
}

function adjustAgreementLinesToProduct(
    lines: SalesAgreementLines,
    canViewUom: boolean,
    allowUOMSelection: boolean,
    productUom: { id: string } | null,
    productUoms: { id: string }[] | null,
) {
    if (!allowUOMSelection || !canViewUom) {
        // If user cannot view UOMs or UOM selection is not allowed,
        // then show lines which either have no UOMs at all or match product's default UOM.
        const productUomId = productUom?.id.toUpperCase();
        return lines.filter(line => !line.uom || line.uom?.id.toUpperCase() === productUomId);
    }

    if (productUoms) {
        // If product has UOMs, then show lines which either have no UOMs at all or have a matching UOM specified.
        return lines.filter(line => {
            if (!line.uom)
                return true;

            const lineUom = line.uom?.id.toUpperCase();
            return productUoms.some(uom => uom.id.toUpperCase() === lineUom);
        });
    }

    return null;
}

function getAgreementLineById(lines: SalesAgreementLines, lineId: string | undefined) {
    return lines.find(line => line.id === lineId);
}

function onStockAvailabilityReceived(state: State, action: requestedStockAvailabilityReceivedAction): State {
    const id = getStockAvailabilityItemId(action.payload);
    const newStockAvailabilityEntry: StockAvailabilityResponse = { ...action.payload, id }
    return {
        ...state,
        stockAvailability: [...state.stockAvailability ?? [], newStockAvailabilityEntry],
    };
}

function onStockAvailabilityRequested(state: State, action: any): State { /*state: State,action: requestStockAvailabilityAction*/
    const id = getStockAvailabilityItemId(action.payload);
    const _stockAvailability = state && state.stockAvailability ? state.stockAvailability.filter(x => x.id !== id) : [];
    return {
        ...state,
        stockAvailability: _stockAvailability,
    };
}

function getStockAvailabilityItemId(record: any) {
    return `${record.productId}:${record.variantId ? record.variantId : ''}:${record.customLength ? record.customLength : ''}`;
}

function onVariantWisePriceRequested(state: State, action: any): State { /*state: State,action: requestVariantWisePriceAction*/
    const id = getStockAvailabilityItemId(action.payload);
    const _variantWisePrice = state && state.variantWisePrice ? state.variantWisePrice.filter(x => x.id !== id) : [];
    return {
        ...state,
        variantWisePrice: _variantWisePrice,
    };
}

function onVariantWisePriceReceived(state: State, action: variantWisePriceReceivedAction): State {
    const id = getStockAvailabilityItemId(action.payload);
    const newVariantWisePriceEntry: VariantWisePriceAvailabilityResponse = { ...action.payload, id };
    return {
        ...state,
        variantWisePrice: [...state.variantWisePrice ?? [], newVariantWisePriceEntry],
    };
}
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BackLink_hyp{color:var(--theme-button_Back_Color,#000);display:inline-block;font-size:var(--theme-text_SmallerSize,12px);line-height:1em;position:relative}html.pointer-mouse .BackLink_hyp:hover{color:var(--theme-button_Back_Hover_Color,grey);text-decoration:none}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .BackLink_hyp:active{color:var(--theme-button_Back_Hover_Color,grey);text-decoration:none}}}@media not all and (min-resolution:.001dpcm){@media (pointer:coarse){.BackLink_hyp:hover{-webkit-tap-highlight-color:var(--theme-button_Back_Hover_Color,#808080)}}}.BackLink_hyp .back-icon{margin-right:.075em;max-height:.2em;max-width:.2em}.BackLink_hyp img.back-icon{font-size:4em}.BackLink_hyp svg.back-icon{color:var(--theme-message_Error_Color,#600502)}", ""]);

// exports
exports.locals = {
	"hyp": "BackLink_hyp"
};
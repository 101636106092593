import { memo } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/sanaText';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './ProductGroup.module.scss';

const ExtendedStock = ({ product, uom, variantId, customLength, stockAvailability }) => {
  const [productStockAvailability, setProductStockAvailability] = useState();

  useEffect(() => {
    if (!stockAvailability) 
      return;
    const _stockAvailability = stockAvailability.find(x => x.id === `${product.id}:${variantId ? variantId : ''}:${customLength ? customLength : ''}`);
    if (_stockAvailability) {
      console.log('stockAvailability:',_stockAvailability);
      setProductStockAvailability(_stockAvailability);
    }
  }, [stockAvailability]);

  /// if a variant product, and variant is not selected
  let stockTextContent, className = '';

  if (product && ((product.variants && product.variants.length > 0 && (!variantId || variantId === '')) || (product.variants.length === 0 && !product.isDress && !stockAvailability))) {
    stockTextContent = <RichText textKey="StockText_NoSelection" />;
  } else if (productStockAvailability) {
    if (productStockAvailability.inventory > 0) {
      stockTextContent = <RichText textKey="StockText_InStock" />;
      className = 'in-stock';
    } else if (productStockAvailability.preOrder) {
      stockTextContent = <RichText textKey="StockText_IncommingStock" />;
      className = 'incomming-stock';
    } else {
      stockTextContent = <RichText textKey="StockText_CustomStock" />;
      className = 'custom-stock'
    }
  } else {
    stockTextContent = <RichText textKey="StockText_Loading" />;
  }

  return (
    <span className={`${styles.stockText} ${className}`}>
      {stockTextContent}
    </span>
  );
  
};

ExtendedStock.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  uom: PropTypes.string,
  variantId: PropTypes.string,
  customLength: PropTypes.string,
};

function mapStateToProps({ page }) {
  const { stockAvailability } = page;
  return { stockAvailability };
}
export default connect(mapStateToProps)(memo(ExtendedStock));

import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ProductGroupContext from './ProductGroupContext';

const ProductGroupProvider = ({ children }) => {
  const [state, setState] = useState(() => ({
    quantities: new Map(),
    quantitiesDependency: {},
  }));

  const quantities = state.quantities;
  const resetQuantities = useCallback(() => setState({
    quantities: new Map(),
    quantitiesDependency: {},
  }), []);

  const updateQuantity = useCallback((productId, uomId, quantity) => {
    setState(({ quantities }) => {      
      if (quantity) {
        let productQuantity = quantities.get(productId);
        if (productQuantity && productQuantity.uomId === uomId)
          productQuantity.quantity = quantity;
        else
          productQuantity = { quantity, uomId };

        quantities.set(productId, productQuantity);
      }
      else
        quantities.delete(productId);

      return { quantities, quantitiesDependency: {} };
    });
  }, []);

  const context = {
    quantities,
    updateQuantity,
    resetQuantities,
  };

  return (
    <ProductGroupContext.Provider value={context}>
      {children}
    </ProductGroupContext.Provider>
  );
};

ProductGroupProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductGroupProvider;
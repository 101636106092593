import styles from './ProductGroup.module.scss';
import { useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import { connect } from 'react-redux';
import { addProducts, specialOrderPopup } from 'behavior/basket';
import ProductGroupContext from './ProductGroupContext';
import scrollIntoView from 'scroll-into-view';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { FormGroup, SanaForm } from 'components/objects/forms';
import { DatePickerField, TextBoxField } from 'components/objects/forms';
import { useSelector } from 'react-redux';

const AddToBasketButton = ({ addProducts, specialOrderPopup }) => {
  const { quantities, resetQuantities } = useContext(ProductGroupContext);
  const addToBasketHandler = useHandlerLockerInPreview(addToBasket.bind(this, quantities, resetQuantities, addProducts, document.forms['SpecialOrderTypeForm']));
  const orderType = useSelector(state => state.basket?.basketSpecialOrderType?.basketSpecialOrderType);
  const referenceNumber = useSelector(state => state.basket?.basketSpecialOrderType?.basketStockReference);
  const weddingDate = useSelector(state => state.basket?.basketSpecialOrderType?.basketSpecialOrderDate);
  const brideName = useSelector(state => state.basket?.basketSpecialOrderType?.basketBrideName);


  return (
    <SanaButton className={styles.addToBasket}
      theme="action"
      textKey="AddToBasket"
      onClick={orderType ? addToBasketHandler : showPopupCustom.bind(this, specialOrderPopup)}
      placeholder={<Placeholder />}
      disabled={quantities.size === 0}
    />
  );
};

AddToBasketButton.propTypes = {
  addProducts: PropTypes.func.isRequired,
  specialOrderPopup: PropTypes.func.isRequired,
  preselectedAgreementLineId: PropTypes.string,
};

export default connect(({ page }) => ({
  preselectedAgreementLineId: page.salesAgreement?.preSelectedLine?.id,
}), { addProducts, specialOrderPopup })(AddToBasketButton);

export function showPopup(show) {
  show();
}

export function showPopupCustom(specialOrderPopup) {
  specialOrderPopup();
}

export function addToBasket(quantities, resetQuantities, addProducts) {
  const lines = [];

  for (const [productId, value] of quantities) {
    if (value instanceof Map) {
      for (const [uomId, uom] of value) {
        for (const [variantId, quantity] of uom) {
          const productLine = {
            productId,
            uomId,
            quantity: quantity.value,
          };

          if (variantId)
            productLine.variantId = variantId;

          lines.push(productLine);
        }
      }

      continue;
    }

    const { quantity, uomId } = value;
    if (!value.quantity.isValid) {
      const id = `${productId}_qty`;
      const invalidQuantityInput = document.getElementById(id);

      scrollIntoView(invalidQuantityInput, { time: 200 }, () => invalidQuantityInput.focus());

      document.getElementById(id).focus();
      return;
    }

    lines.push({
      productId,
      quantity: quantity.value,
      uomId,
    });
  }

  if (lines.length > 0)
    addProducts(lines);

  resetQuantities();
}

//export function addToBasket(quantities, resetQuantities, addProducts, form) {
//  const lines = [];
//  form = document.forms['SpecialOrderTypeForm'];
//  const weddingDate = form && form.elements['deliveryDate'].value;
//  const reference = form && form.elements['reference'].value;
//  const brideName = form && form.elements['brideName'].value;

//  for (const [productId, value] of quantities) {
//    if (value instanceof Map) {
//      for (const [uomId, uom] of value) {
//        for (const [variantId, quantity] of uom) {
//          const productLine = {
//            productId,
//            uomId,
//            quantity: quantity.value,
//          };

//          if (variantId)
//            productLine.variantId = variantId;

//          lines.push(productLine);
//        }
//      }

//      continue;
//    }

//    const { quantity, uomId } = value;
//    if (!value.quantity.isValid) {
//      const id = `${productId}_qty`;
//      const invalidQuantityInput = document.getElementById(id);

//      scrollIntoView(invalidQuantityInput, { time: 200 }, () => invalidQuantityInput.focus());

//      document.getElementById(id).focus();
//      return;
//    }

//    lines.push({
//      productId,
//      quantity: quantity.value,
//      uomId,
//    });
//  }

//  //if (lines.length > 0)
//    addProducts(lines, null, null, reference, brideName, weddingDate);

//  resetQuantities();
//}

import { forwardRef, useCallback, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { scroll$, orientationChange$, useEventObservable } from 'utils/rxjs';
import ZoomMedia from './ZoomMedia';
import { useResponsiveBreakpoints } from 'utils/layout';

const GalleryImage = forwardRef(({
  imgProps,
  onLoad: onImageLoad,
  index,
  showLargeGallery,
  zoomState,
  item,
  preventZoom,
}, ref) => {
  const [zoom, setZoom] = zoomState;
  const { xs } = useResponsiveBreakpoints();

  const zoomSrc = !preventZoom && !xs && zoom && ref.current && getZoomSrc(item);

  const onLoad = useCallback(e => {
    ref.current.addEventListener('mouseover', showZoom);
    ref.current.addEventListener('mousemove', showZoomOnMove);

    onImageLoad(e);
  }, [item]);

  const showZoom = useCallback(() => {
    if (item && item.large)
      setZoom(true);
  }, [item]);

  const showZoomOnMove = () => {
    ref.current.removeEventListener('mousemove', showZoomOnMove);
    showZoom();
  };

  const handleImageClick = () => {
    !zoom && showZoom();
    showLargeGallery && showLargeGallery();
  };

  const handleImageKeyUp = e => {
    if (e.key === 'Enter')
      handleImageClick();
  };

  const hideZoom = useCallback(e => {
    setZoom(false);
  }, [item]);

  useEventObservable(scroll$, hideZoom);
  useEventObservable(orientationChange$, hideZoom);

  useEffect(() => {
    if (ref.current.complete)
      onLoad();
  }, []);

  useEffect(() => () => {
    ref.current.removeEventListener('mouseover', showZoom);
    ref.current.removeEventListener('mousemove', showZoomOnMove);
  }, [index]);

  useLayoutEffect(() => {
    setZoom(false);
  }, [index]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions*/}
      <img
        src={imgProps.src}
        title={imgProps.title}
        alt={imgProps.title}
        className={imgProps.className}
        onLoad={onLoad}
        onClick={handleImageClick}
        onKeyUp={handleImageKeyUp}
        ref={ref}
        onMouseDown={preventMiddleMouseButtonScroll}
        onMouseLeave={hideZoom}
        draggable={false}
        onDragStart={preventDragEvent}
      />
      {zoomSrc && <ZoomMedia src={zoomSrc} initiatorNode={ref.current} />}
    </>
  );
});

GalleryImage.propTypes = {
  imgProps: PropTypes.shape({
    src: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
  }).isRequired,
  onLoad: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  showLargeGallery: PropTypes.func,
  zoomState: PropTypes.array.isRequired,
  item: PropTypes.shape({
    large: PropTypes.string,
  }),
  preventZoom: PropTypes.bool,
};

export default GalleryImage;

function preventMiddleMouseButtonScroll(e) {
  if (e.button !== 1)
    return;

  e.preventDefault();
}

function getZoomSrc(item) {
  return (item && item.large) || null;
}

function preventDragEvent(e) {
  e.preventDefault();
}

import { ReactNode, ReactElement, forwardRef } from 'react';
import { encodeSpacesInUrl } from 'utils/url';
import { isIndexed, SystemRouteData } from 'routes';
import RouteLink from './RouteLink';
import NavigationLink from './NavigationLink';
import { createRouteData } from './helpers';
import { HTMLAnchorFactory, SystemLinkTo, LinkTo } from './types';

export type Props = Omit<Parameters<typeof NavigationLink>[0], 'createLink' | 'to'> & {
  to?: LinkTo;
  children?: ReactNode;
  disabled?: boolean;
} & Omit<JSX.IntrinsicElements['a'], 'itemRef' | 'onClick' >;

type EmptyLinkProps = Omit<Props, 'to' | 'url'> & {
  ref?: React.Ref<HTMLAnchorElement>;
};

export type SystemLinkProps = EmptyLinkProps & {
  to: SystemLinkTo;
  url?: string;
};

export type LinkProps = EmptyLinkProps & {
  to?: LinkTo;
  url: string;
};

declare function Link(props: LinkProps): ReactElement;
declare function Link(props: SystemLinkProps): ReactElement;

export default forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const {
    to,
    url,
    omitScroll,
    replaceHistory,
    options,
    onClick,
    children,
    rel,
    disabled,
    ...attributes
  } = props;

  const route = to && createRouteData(to);

  const createLink: HTMLAnchorFactory = (href, onLinkClick) => {
    let relValue = rel;
    if (relValue === undefined && route && !isIndexed(route.routeName))
      relValue = 'nofollow';

    const conditionalAttributes = disabled
      ? { 'aria-disabled': true }
      : {
        href: href ? encodeSpacesInUrl(href) : undefined,
        rel: relValue,
        onClick: onLinkClick,
      };

    return (
      <a
        data-broken={href ? null : 'true'}
        ref={ref}
        {...conditionalAttributes}
        {...attributes}
      >
        {children}
      </a>
    );
  };

  if (!url && to)
    return (
      <RouteLink
        to={route as SystemRouteData}
        omitScroll={omitScroll}
        replaceHistory={replaceHistory}
        options={options}
        onClick={onClick}
        createLink={createLink}
      />
    );

  return (
    <NavigationLink
      url={url}
      to={route}
      omitScroll={omitScroll}
      replaceHistory={replaceHistory}
      options={options}
      onClick={onClick}
      createLink={createLink}
    />
  );
}) as typeof Link;

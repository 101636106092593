import styles from './header/Header.module.scss';
import { memo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import MobileHeaderTemplate from './header/MobileHeaderTemplate';
import TabletHeaderTemplate from './header/TabletHeaderTemplate';
import DesktopHeaderTemplate from './header/DesktopHeaderTemplate';
import withHeaderContext from './headerContext/withHeaderContext';
import { useResponsiveBreakpoints } from 'utils/layout';
import { joinClasses } from 'utils/helpers';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import { Media } from 'components/responsive';

const Header = ({ isDesktopNavHovered, setDesktopNavHoverStatus }) => {
  const ref = useRef();
  const { xs, sm, md, lg, xl } = useResponsiveBreakpoints();
  const isDesktop = md || lg || xl;

  useEffect(() => toggleOverflowAnchorState, []);

  useEffect(() => {
    if (!!document?.activeElement)
      return;

    // Fixes document.activeElement is not present on header type change if an element in previous rendered
    // type of header was in focus in IE11. In other browsers document.activeElement is set to the body element
    document?.body?.focus();
  }, [xs, sm, isDesktop]);

  useEffect(() => {
    !isDesktop && setDesktopNavHoverStatus(false);
  }, [isDesktop]);

  useEffect(() => {
    if (!isDesktop)
      return;

    if (isDesktopNavHovered) {
      const className = 'allow-root-overflow';
      document?.documentElement?.classList?.add(className);

      return () => {
        document?.documentElement?.classList?.remove(className);
      };
    }
  }, [isDesktop, isDesktopNavHovered]);

  const headerClassName = joinClasses(
    isDesktop && styles.desktop,
    xs && styles.mobile,
    isDesktop && isDesktopNavHovered && styles.navIsHovered,
  );

  const navbar =  typeof document !== 'undefined'
    ? document?.querySelector("header")
    : null;

  const onScroll = () => {
    const scroll = document?.documentElement?.scrollTop
    if( navbar != null ){
      if (scroll > 0 ) {
        navbar.classList.add("is-sticky");
      } else {
        navbar.classList.remove("is-sticky");
      }
    }
  };


  if(typeof window !== 'undefined')
    window.addEventListener('scroll', onScroll)

  return (
    <header id="header" className={`${headerClassName} is-sticky`} ref={ref}>
      <Media at="xs">
        <MobileHeaderTemplate />
      </Media>
      <Media at="sm">
        <TabletHeaderTemplate />
      </Media>
      <Media greaterThanOrEqual="md">
        <DesktopHeaderTemplate />
      </Media>
    </header>
  );
};

Header.propTypes = {
  isDesktopNavHovered: PropTypes.bool.isRequired,
  setDesktopNavHoverStatus: PropTypes.func.isRequired,
};

const MemoizedHeader = memo(Header);

export default withHeaderContext(MemoizedHeader);

import btnStyles from 'components/primitives/buttons/Button.module.scss';
import type { ButtonProps as ButtonPrimitiveProps } from 'components/primitives/buttons';
import type { ReactElement } from 'react';
import { withStyles } from 'isomorphic-style-loader';
import Link, { Props, SystemLinkProps, LinkProps } from './Link';
import type { SystemLinkTo } from './types';
import { ButtonContent, getButtonClassNames } from 'components/primitives/buttons';

export type ButtonProps = Omit<ButtonPrimitiveProps, 'children'>;
type LinkButtonProps = Omit<Props, 'ref'> & ButtonProps;

function LinkButton(props: Omit<LinkProps, 'ref'> & ButtonProps): ReactElement;
function LinkButton(props: Omit<SystemLinkProps, 'ref'> & ButtonProps): ReactElement;
function LinkButton({
  to,
  children,
  className = '',
  size = 'md',
  theme = 'regular',
  icon,
  iconOnLeft,
  noContentWrapper,
  ...attributes
}: LinkButtonProps) {
  const classNames = getButtonClassNames(size, theme, icon === false, iconOnLeft, className);

  return (
    <Link to={to as SystemLinkTo} className={classNames} {...attributes}>
      <ButtonContent icon={icon} noWrapper={noContentWrapper}>
        {children}
      </ButtonContent>
    </Link>
  );
}

/*
Function 'getButtonClassNames' returns string containing module class names from 'Button.module.scss' stylesheet, but because there is no direct usages of
imported from module classes in LinkButton component it will not be wrapped up by withStyles-babel-plugin with isomorphic-style-loader's withStyles function.
Manual wrapping should be done in this case for 'Button.module.scss' stylesheet to be injected into page head section on LinkButton component render.
*/
const StyledLinkButton = withStyles(btnStyles as any)(LinkButton) as typeof LinkButton;

/*
Component should not be exported as default because of manual wrapping with isomorphic-style-loader's withStyles function. Manually wrapped component exported
as default will be processed by withStyles-babel-plugin which will result in duplicate import of 'withStyles' function and 'Module parse failed: Identifier
'withStyles' has already been declared' error.
*/
export { StyledLinkButton as LinkButton };

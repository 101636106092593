import content from 'components/objects/content';
import product from 'components/objects/product';
import productList from 'components/objects/productList/page';
import productGroupPage from 'components/objects/productGroup';
import search from 'components/objects/productList/search';
import plpWithCategory from 'components/objects/productList/withCategory';
import faq from 'components/objects/faq';
import news from 'components/objects/news';
import system from 'components/objects/systemPages';
import error from 'components/objects/error/page';
import basket from 'components/objects/basket';
import offline from 'components/objects/offlineMode/page';
import productComparison from 'components/objects/productComparison/page';
import closedStoreLogin from 'components/objects/closedStore/login';
import closedStoreForgotPassword from 'components/objects/closedStore/forgotPassword';
import closedStoreResetPassword from 'components/objects/closedStore/resetPassword';
import wishList from 'components/objects/wishList';
import contactUs from 'components/objects/contactUs';
import visualDesigner from 'components/objects/visualDesigner';
import forgotPassword from 'components/objects/forgotPassword';
import resetPassword from 'components/objects/resetPassword';
import login from 'components/objects/login';

const defaultPageRenderers = {
  ...content,
  ...product,
  ...productList,
  ...productGroupPage,
  ...search,
  ...plpWithCategory,
  ...faq,
  ...news,
  ...system,
  ...error,
  ...basket,
  ...offline,
  ...productComparison,
  ...closedStoreLogin,
  ...closedStoreForgotPassword,
  ...closedStoreResetPassword,
  ...wishList,
  ...contactUs,
  ...visualDesigner,
  ...forgotPassword,
  ...resetPassword,
  ...login,
};

export default defaultPageRenderers;

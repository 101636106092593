import { useContext } from 'react';
import styles from './ProductGroup.module.scss';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { Placeholder } from 'components/primitives/placeholders';
import { connect, useSelector } from 'react-redux';
import ProductGroupContext from './ProductGroupContext';
import { addProducts, specialOrderPopup } from 'behavior/basket';

const ExtendedAddToBasketButton = ({ product, variantId, customLength, deliveryDate, deliveryType, preselectedAgreementLineId, addProducts, specialOrderPopup }) => {
  // const referenceNumber = useSelector(state => state.basket?.basketSpecialOrderType?.basketStockReference);
  // const weddingDate = useSelector(state => state.basket?.basketSpecialOrderType?.basketSpecialOrderDate);
  // const brideName = useSelector(state => state.basket?.basketSpecialOrderType?.basketBrideName);
  //const basket = useSelector(state => state);
  const { quantities  } = useContext(ProductGroupContext);
  
  let quantitySelected = { value: 1, isValid: true };
  if(quantities.size>0 && quantities.get(product.id) !== undefined)
  {
    const { quantity }  = quantities.get(product.id);    
    quantitySelected = quantity;
  }
  
  const orderType = useSelector(state => state.basket?.basketSpecialOrderType?.basketSpecialOrderType);
  
  const addToBasketHandler = () => {
    if(!orderType || (orderType === 'Bride' && !deliveryDate))  {
      specialOrderPopup();
      return;
    }
    if(!deliveryDate || !quantitySelected.isValid) 
      return;    
    else {
      const lines = {
        productId: product.id,
        quantity: quantitySelected.value,
        uomId: product.uom.id ?? '',
        variantId,
        salesAgreementLineId: preselectedAgreementLineId,
        isDress: product.isDress,
        customLength,
        availableDate: deliveryDate,
        deliveryType,
      };
      addProducts([lines]);
    }

  };

  return (
    <SanaButton className={styles.addToBasket}
      theme="action"
      textKey="AddToBasket"
      onClick={addToBasketHandler}
      placeholder={<Placeholder />}
    />
  );
};

ExtendedAddToBasketButton.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool,
    isDress: PropTypes.bool.isRequired,
    variants: PropTypes.array,
  }),
  variantId: PropTypes.string,
  customLength: PropTypes.string,
  deliveryDate: PropTypes.string,
  deliveryType: PropTypes.string,
  preselectedAgreementLineId: PropTypes.string,
  addProducts: PropTypes.func,
  specialOrderPopup: PropTypes.func,
};

export default connect(({ page }) => ({ preselectedAgreementLineId: page.salesAgreement?.preSelectedLine?.id }), { addProducts, specialOrderPopup })(ExtendedAddToBasketButton);
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Modal, useModal } from 'components/objects/modals';
import PropTypes from 'prop-types';
import StockAvailabilityForm from './stockAvailabilityForm';
import { useOnLocationChanged, useCultureName } from 'utils/hooks';
import { requestStockAvailability } from 'behavior/pages/product';
import { RichText } from 'components/sanaText';
import { toLocaleDate } from 'utils/format';

const PromisedDeliveryDate = ({
    product,
    uom,
    variantId,
    customLength,
    bridesName,
    weddingDate,
    orderType,
    stockAvailability,
    updateDeliveryDetails,
    selectedDate,
  }) => {
  const { opened, show, hide } = useModal();
  const dispatch = useDispatch();
  useOnLocationChanged(hide);

  const [showLoading, setShowLoading] = useState(true);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(selectedDate);
  const [productStockAvailability, setProductStockAvailability] = useState(undefined);
  const [isSelectedDeliveryDate, setIsSelectedDeliveryDate] = useState(false);
  const [isVariantIdChange, setIsVariantIdChange] =useState(false);
  const [isCustomLengthChange, setIsCustomLengthChange] =useState(false);
  const [selectedDeliveyMethod, setSelectedDeliveryMethod] = useState(undefined)

  const culture = useCultureName();

  useEffect(()=>{
    setIsCustomLengthChange(true);
  }, [customLength]);

  useEffect(()=>{
    setIsVariantIdChange(true);
  }, [variantId]);

  useEffect(() => {

    if(isSelectedDeliveryDate && !isVariantIdChange && !isCustomLengthChange){
      setShowLoading(false);
      return;
    }

    setProductStockAvailability(undefined);
    setSelectedDeliveryDate(undefined);
    updateDeliveryDetails(undefined);

    if (!stockAvailability)
      return;

    const _stockAvailability = stockAvailability.find(x => x.id === `${product.id}:${variantId ? variantId : ''}:${customLength ? customLength : ''}`);

    if (_stockAvailability) {
      setShowLoading(true);
      setProductStockAvailability(_stockAvailability);

      if(_stockAvailability.onHand){
        updateData({method: 'Stock', date: _stockAvailability.onHand});
      } else if (_stockAvailability.preOrder) {
        updateData({method: 'PreOrder', date: _stockAvailability.preOrder});
      } else if (_stockAvailability.standard) {
        updateData({method: 'Standard', date: _stockAvailability.standard})
      } else if (_stockAvailability.rush) {
        updateData({method: 'Rush', date: _stockAvailability.rush})
      } else if (_stockAvailability.misc) {
        updateData({method: 'Misc', date: _stockAvailability.misc})
      } else if (_stockAvailability.quickShip) {
        updateData({method: 'QuickShip', date: _stockAvailability.quickShip})
      } else {
        updateData(undefined)
      }
      setShowLoading(false);
      setIsCustomLengthChange(false);
      setIsVariantIdChange(false);
    }
  }, [stockAvailability,variantId,customLength]);

  const validateBasketInfoAndPopulateAvailabilityPopup = e => {
    e.preventDefault();

    // open popup to get order type, wedding date or reference
    if(!orderType || (orderType === 'Bride' && !weddingDate))  {
      // TODO: N open popup to get order type, wedding date or reference
      //alert('trigger popup'); Not needed, as in the variant dropdown already triggeres
    }
    // if variant product, variant option must be selected
    else if (!product) return;
    else if ((product.variants && product.variants.length > 0) && (!variantId || variantId === '')) return;

    setShowLoading(true);
    dispatch(requestStockAvailability({ productId: product.id, uom, variantId, customLength, weddingDate : weddingDate ? new Date(weddingDate).toISOString() : undefined }));
    show();
  };

  const onConfirmHandler = value => {

    updateData(value);
    hide();
  };

  const updateData = value => {

    if(value !== undefined && value.toString().length !==0){
      setIsSelectedDeliveryDate(true);
      setSelectedDeliveryMethod(value.method);
    }

    setSelectedDeliveryDate(value && value.date ? value.date : undefined);
    updateDeliveryDetails(value);
  };

  const displayDeliveryDate = () => {
    return selectedDeliveryDate ? toLocaleDate(selectedDeliveryDate, culture): '';
  };

  return (
    <>
      {product &&
        <>
        <div onClick={validateBasketInfoAndPopulateAvailabilityPopup}>
          <input type="text"  placeholder={displayDeliveryDate()} />
          <span>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="calendar-alt" className="svg-inline--fa fa-calendar-alt fa-w-14 Icons_default-icon " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"></path></svg>
          </span>
        </div>

          <Modal opened={opened} hide={hide} size="large" resetContentOnClose className="stock_availbility-popup">
            {showLoading &&
              <div>
                <RichText textKey="StockAvailablity_Popup_LoadingText" children="<span>Loading...</span>" />
              </div>}
            {!showLoading &&
              <StockAvailabilityForm
                key={product.id}
                bridesname={bridesName}
                weddingdate={weddingDate}
                stockAvailability={productStockAvailability}
                onConfirm={onConfirmHandler}
                closePopup={hide}
                selectedDeliveryMethod = {selectedDeliveyMethod}
                selectedDeliveryDate = {selectedDeliveryDate}
              />}
          </Modal>
        </>
      }
    </>
  );
};

PromisedDeliveryDate.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool,
    isDress: PropTypes.bool,
    hasVariants: PropTypes.bool,
  }).isRequired,
  uom: PropTypes.string,
  variantId: PropTypes.string,
  customLength: PropTypes.string,
  bridesName: PropTypes.string,
  weddingDate: PropTypes.string,
  orderType: PropTypes.string,
  updateDeliveryDetails: PropTypes.func.isRequired,
};

function mapStateToProps({ page, basket }) {
  const { stockAvailability } = page;
  const { basketSpecialOrderType } = basket;
  const bridesName = basketSpecialOrderType?.basketBrideName;
  const orderType = basketSpecialOrderType?.basketSpecialOrderType;
  const weddingDate = basketSpecialOrderType?.basketSpecialOrderDate;
  return { stockAvailability, bridesName, weddingDate, orderType };
}

export default connect(mapStateToProps)(PromisedDeliveryDate);
import { FunctionComponent, useEffect, useState } from 'react';

export default function renderOnce<P>(WrappedComponent: FunctionComponent<P>) {
  return (props: P) => {
    const [hideOnClient, setHideOnClient] = useState(false);
    useEffect(() => setHideOnClient(true), []);

    if (!hideOnClient)
      return <WrappedComponent {...props} />;

    return null;
  };
}
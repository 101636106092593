import type { Handler } from '../types';
import type { Api } from 'utils/api';
import { resetPasswordPageQuery } from './queries';
import { mergeMap, switchMap, pluck, first, map } from 'rxjs/operators';
import { RouteName, routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { StoreType, areSettingsLoaded } from 'behavior/settings';
import { ContentSystemPage, ContentSystemPageData, initContentSystemPage, initContentSystemPageContent, loadContentSystemPageQuery } from '../system';

// Customizations
// [216777] Automated Email Order Confirmation

const handler: Handler<ResetPasswordRouteData, ResetPasswordPage> = (routeData, state$, { api }) => {
    return state$.pipe(
        pluck('settings'),
        first(areSettingsLoaded),
        switchMap(settings => {
            const shouldLoadClosedStorePage = routeData.params?.previewToken
                ? routeData.params?.closedStore
                : settings.storeType === StoreType.Closed;

            return shouldLoadClosedStorePage
                ? loadClosedStoreResetPasswordPage(routeData, api)
                : loadSystemResetPasswordPage(routeData, api);
        }),
    );
};

export default handler;

function loadClosedStoreResetPasswordPage(routeData: ResetPasswordRouteData, api: Api) {
    if (routeData.params.previewToken)
        return loadClosedStoreResetPasswordPreviewPage(api);

    return api.graphApi<ClosedStoreResetPasswordResponse>(resetPasswordPageQuery('closedStoreResetPassword'), { token: routeData.params.token }).pipe(
        mergeMap(({ profile: { userData }, pages: { closedStoreResetPassword } }) => {
            if (!userData)
                return of({ action$: of(navigateTo(routesBuilder.forForgotPassword(), undefined, { replaceHistory: true })) });

            const result = {
                ...closedStoreResetPassword,
                emptyLayout: true,
                component: PageComponentNames.ClosedStoreResetPassword as const,
                userData,
                token: routeData.params.token,
            };

            return of({ page: initContentSystemPage(result) });
        }),
    );
}

function loadSystemResetPasswordPage(routeData: ResetPasswordRouteData, api: Api) {
    if (routeData.params.previewToken)
        return loadResetPasswordPreviewPage(api);

    return api.graphApi<PublicStoreResetPasswordResponse>(resetPasswordPageQuery('resetPassword'), { token: routeData.params.token }).pipe(
        mergeMap(({ profile: { userData }, pages: { resetPassword } }) => {
            if (!userData)
                return of({ action$: of(navigateTo(routesBuilder.forForgotPassword(), undefined, { replaceHistory: true })) });

            const result = {
                ...resetPassword,
                component: PageComponentNames.ResetPassword as const,
                userData,
                token: routeData.params.token,
            };

            return of({ page: initContentSystemPage(result) });
        }),
    );
}

function loadClosedStoreResetPasswordPreviewPage(api: Api) {
    return api.graphApi<ClosedStoreResetPasswordPreviewResponse>(loadContentSystemPageQuery('closedStoreResetPassword')).pipe(
        map(({ pages: { closedStoreResetPassword } }) => ({
            page: {
                ...closedStoreResetPassword,
                emptyLayout: true,
                component: PageComponentNames.ClosedStoreResetPassword as const,
                token: '',
            },
        })),
        initContentSystemPageContent(),
    );
}

function loadResetPasswordPreviewPage(api: Api) {
    return api.graphApi<PublicStoreResetPasswordPreviewResponse>(loadContentSystemPageQuery('resetPassword')).pipe(
        map(({ pages: { resetPassword } }) => ({
            page: {
                ...resetPassword,
                component: PageComponentNames.ResetPassword as const,
                token: '',
            },
        })),
        initContentSystemPageContent(),
    );
}

type UserData = {
    name: string;
    email: string;
};

type ResetPasswordRouteData = {
    routeName: RouteName.ResetPassword;
    params: {
        token: string;
        previewToken?: string;
        language?: number;
        closedStore?: boolean;
    };
};

type PublicStoreResetPasswordPage = ContentSystemPage & {
    component: PageComponentNames.ResetPassword;
    userData?: UserData;
    token: string;
};

type ClosedStoreResetPasswordPage = {
    component: PageComponentNames.ClosedStoreResetPassword;
    emptyLayout: boolean;
    userData?: UserData;
    token: string;
};

type ResetPasswordPage = ClosedStoreResetPasswordPage | PublicStoreResetPasswordPage;

type ClosedStoreResetPasswordResponse = {
    profile: {
        userData: UserData | null;
    };
    pages: {
        closedStoreResetPassword: ContentSystemPageData;
    };
};

type PublicStoreResetPasswordResponse = {
    profile: {
        userData: UserData | null;
    };
    pages: {
        resetPassword: ContentSystemPageData;
    };
};

type ClosedStoreResetPasswordPreviewResponse = {
    pages: {
        closedStoreResetPassword: ContentSystemPageData;
    };
};

type PublicStoreResetPasswordPreviewResponse = {
    pages: {
        resetPassword: ContentSystemPageData;
    };
};

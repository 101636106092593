import { Helmet } from 'react-helmet';
import { shallowEqual, useSelector } from 'react-redux';
import { useServices } from 'utils/services';
import { createUrl } from 'utils/url';
import renderOnce from 'components/objects/renderOnce';

const AlternateLinks = () => {
  const index = useSelector(s => s.page.index);
  const languages = useSelector(s => s.localization.languages, shallowEqual);
  const alternateUrls = useSelector(s => s.localization.alternateUrls, shallowEqual);
  const { context: { origin } } = useServices();

  if (!index || !languages.length || alternateUrls.length <= 1)
    return null;

  const defaultLanguage = languages.find(language => language.isDefault);
  const id = defaultLanguage && defaultLanguage.id;
  const url = alternateUrls.find(({ key }) => +key === id);
  const defaultLanguageUrl = createUrl(url.path, origin);

  const getLanguageName = id => {
    const language = languages.find(language => language.id === id);
    return language && language.name;
  };

  return (
    <Helmet>
      {alternateUrls.map(({ key, path }, index) => (
        <link
          key={index}
          rel="alternate"
          hrefLang={getLanguageName(+key)}
          href={encodeURI(createUrl(path, origin))}
        />
      ))}
      <link rel="alternate" hrefLang="x-default" href={encodeURI(defaultLanguageUrl)} />
    </Helmet>
  );
};

export default renderOnce(AlternateLinks);
